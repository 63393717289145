import moment from 'moment';
import * as Yup from 'yup';

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const today = new Date();

export const schemaUsuarioPassword = Yup.object().shape({
    USR_Id: Yup.string()
        .min(2, "Muy corto!")
        .required('Es requerido un nombre'),
    USR_NombreCom: Yup.string().required('Es requerido un nombre'),
    USR_Email: Yup.string().required('Es requerido un email'),
    USR_Telefono: Yup.string().required('Es requerido un Teléfono'),
    USR_Direccion: Yup.string().required('Es requerido una dirección'),
    USR_Descripcion: Yup.string().required('Es requerido un Descripción'),
    USR_Password: Yup.string()
        .matches(uppercaseRegex, 'Un caracter Mayuscula requerido!')
        .matches(lowercaseRegex, 'Un caracter Minuscula requerido!')
        .matches(numericRegex, 'Un numero requerido!')
        .min(8, 'Minimo 8 caracteres requeridos'),
    USR_RePassword: Yup.string()
        .oneOf([Yup.ref('USR_Password')], 'Constraseña no coinciden'),
    USR_Sucursales: Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required('Es requerido una sucursal'),
            value: Yup.number().required('Es requerido una sucursal'),
        })
    )
})

export const schemaUsuarioSinPassword = Yup.object().shape({
    USR_Id: Yup.string().required('Es requerido un nombre'),
    USR_NombreCom: Yup.string().required('Es requerido un nombre'),
    USR_Email: Yup.string().required('Es requerido un email'),
    USR_Telefono: Yup.string().required('Es requerido un Teléfono'),
    USR_Direccion: Yup.string().required('Es requerido una dirección'),
    USR_Descripcion: Yup.string().required('Es requerido un Descripción'),
    USR_Sucursales: Yup.array().of(
        Yup.object().shape({
            label: Yup.string().required('Es requerido una sucursal'),
            value: Yup.number().required('Es requerido una sucursal'),
        })
    )
})

export const schemaServices = Yup.object().shape({
    SERV_Fecha: Yup.date()
      .min(today, 'La fecha ingresada no puede ser de dias anteriores')
      .required('Es requerido una fecha valida'),
    // SERV_Sucursal: Yup.number().required('Seleccione una sucursal'),
    // SERV_Barrio: Yup.string().required('Seleccione un barrio/zona/ciudad'),
    SERV_Cliente: Yup.string().max(100).required('Es requerido un cliente'),
    SERV_Telefono: Yup.number().required('Es requerido un numero de telefono').positive().integer(),
    SERV_Email: Yup.string().email('Email invalido').max(150).required('Es requerido un correo'),
    SERV_Direccion: Yup.string().max(250).required('Es requerido una direccion'),
    SERV_Referencia: Yup.string().max(100),
    SERV_Estado: Yup.number().required('Es requerido un estado'),
});

const yesterday = new Date(Date.now() -86400000);
export const schemaServicesU = Yup.object().shape({
    SERV_Fecha: Yup.date()
	.min(yesterday, 'La fecha ingresada no puede ser de dias anteriores')
	.required('Es requerdi una fecha valida'),
    SERV_Cliente: Yup.string().max(100).required('Es requerido el nombre del cliente'),
    SERV_Telefono: Yup.number().required('Es requerido el numero de telefono').positive().integer(),
    SERV_Email: Yup.string().email('Email invalido').max(150).required('Es requerido un correo'),
    SERV_Direccion: Yup.string().max(250).required('Es requerida una direccion'),
    SERV_Referencia: Yup.string().max(100),
    SERV_Estado: Yup.number().required('Es requerido un estado'),
});

export const schemaGastos = Yup.object().shape({
    // GAST_Fecha: Yup.date().min(new Date().getMonth(), 'La fecha ingresada debe ser del mes actual').required('Es requerido una fecha valida'),
    GAST_Fecha: Yup.date().required('Es requerido una fecha valida'),
    // GAST_Descripcion: Yup.string().max(50).required('Es requerido un motivo de pago'),
    GAST_Descripcion: Yup.string().test('len', 'Excedió el maximo de 50 caracteres.', val => val.toString().length <= 50),
    GAST_NumFact: Yup.string().test('len', 'Excedió el maximo de 15 caracteres.', val => val.toString().length <= 15),
    GAST_importe: Yup.number()
        .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
        .positive('Importe debe ser mayor de 0'),
    GAST_Sucursal: Yup.string().required('Es requerido una sucursal'),
});
export const schemaGastosEdit = Yup.object().shape({
    // GAST_Descripcion: Yup.string().max(50).required('Es requerido un motivo de pago'),
    GAST_Descripcion: Yup.string().test('len', 'Excedió el maximo de 50 caracteres.', val => val.toString().length <= 50),
    GAST_NumFact: Yup.string().test('len', 'Excedió el maximo de 15 caracteres.', val => val.toString().length <= 15),
    GAST_importe: Yup.number()
        .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
        .positive('Importe debe ser mayor de 0'),
    GAST_Sucursal: Yup.string().required('Es requerido una sucursal'),
});

export const schemaDeposito = Yup.object().shape({
    DP_MtdDepo: Yup.number().required('Es requerido un metodo de deposito'),
    DP_Referencia: Yup.string().test('len', 'Excedió el maximo de 50 caracteres.', val => val.toString().length <= 50),
    DP_Fecha: Yup.date().required('Es requerido una fecha valida'),
    // DP_Referencia: Yup.string().max(50),
    DP_Monto: Yup.number()
        .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
        .positive('Importe debe ser mayor de 0'),
    DP_Sucursal: Yup.string().required('Es requerido una sucursal'),
})

export const schemaSucursal = Yup.object().shape({
    // DP_MtdDepo: Yup.number().required('Es requerido un metodo de deposito'),
    SUC_Nombre: Yup.string().test('len', 'Excedió el maximo de 50 caracteres.', val => val.toString().length <= 50),
    // DP_Fecha: Yup.date().required('Es requerido una fecha valida'),
    // DP_Referencia: Yup.string().max(50),
    SUC_Nafta: Yup.number()
        .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
        .positive('Importe debe ser mayor de 0'),
    // DP_Sucursal: Yup.string().required('Es requerido una sucursal'),
})