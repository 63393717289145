import { gql } from 'apollo-boost';

//query

export const GET_TAX = gql`
  query GetTax{
    GetTax{
      TAX_Id
      TAX_Valor
      TAX_FModificado
      TAX_ByModify
      TAX_Activo
    }
  }
`;

//mutation
export const NEW_TAX = gql`
    mutation createTax($data: TaxInput){
        createTax(data:$data){
            TAX_Id
            TAX_Valor
            TAX_ByModify            
        }
    }
`;

export const EDIT_TAX = gql`
    mutation editTax($data: TaxInput){
        editTax(data:$data){
            TAX_Id
            TAX_Valor
            TAX_ByModify            
        }
    }
`;