import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
// import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import Switch from '@material-ui/core/Switch';
import { schemaSucursal } from '../../../../shared/validators/Forms';
import { NEW_SUCURSAL } from '../../../../graphql/Sucursales';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const itemSession = JSON.parse(sessionStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);


  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // const [datasSucursal, setDatasSucursal] = useState([])
  // const [datasMtdPago, setDatasMtdPago] = useState([])

  // const { data: dataMtdPago, loading: loading3, error: error3 } = useQuery(GET_MTD_PAGO);
  // const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  const [sendMtdPago] = useMutation(NEW_SUCURSAL, {
    onCompleted: data => {
      console.log(data);
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/Sucursal',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })
   //Funcion para enviar datos al backend
   const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({})
    console.log(values)
    //Creo el objeto a validar
    let data = {
      SUC_Nombre: values.SUC_Nombre,
      SUC_Nafta: String(values.SUC_Nafta),
      SUC_ByModify: itemSession.USR_Id, 
      SUC_Comi: values.SUC_Comi,
    }
    //Valido con promesa
    schemaSucursal.validate(data)
      //Si es valido se envia el formulario
      .then(async (res) => {
        await sendMtdPago({ variables: { data } })
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch((err) => {
        setLoading(false);
        let object = {}
        console.log(err)
        object[err.path] = err.errors
        setErrorForm(object)
      })

  }

  useEffect(() => {    
    // if (dataMtdPago && loading3 === false && error3 === undefined) {
    //   let thing = []
    //   dataMtdPago.GetDatamtdpago.map(item => {
    //     return thing.push({ label: item.mtd_nombre, value: item.mtd_id })
    //   })
    //   setDatasMtdPago(thing)
    // }
  }, [
    // dataMtdPago,
    // loading3,
    // error3
  ])
  return (
    <Formik
      initialValues={{
        SUC_Nombre: '',
        SUC_Nafta: '',
        SUC_Comi: '',
        
      }}
      validationSchema={Yup.object().shape({      
        SUC_Nombre: Yup.string().max(25).required('Campo requerido!'),        
      })}
      onSubmit={async (values, actions) => {
        await sendMtdPago({
          variables: {
            data: {
              SUC_Nombre: values.SUC_Nombre,
              SUC_Nafta: Number(values.SUC_Nafta),
              SUC_ByModify: itemSession.USR_Id,
              SUC_Comi: values.SUC_Comi,            
            },            
          }
        })
        actions.resetForm({
          values: {
            SUC_Nombre: '',
            
          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Genera una nueva sucursal"
              title="Sucursales"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >                
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Sucursal"
                    name="SUC_Nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SUC_Nombre}
                    
                  />
                  {errors.SUC_Nombre && touched.SUC_Nombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.SUC_Nombre}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nafta por servicio:"
                    name="SUC_Nafta"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SUC_Nafta}
                    inputProps={{ type: 'number', pattern: '[0-9]*' }}                     
                  />
                  {errors.SUC_Nafta && touched.SUC_Nafta ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.SUC_Nafta}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Comision Operario:"
                    name="SUC_Comi"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SUC_Comi}
                    inputProps={{ type: 'number', pattern: '[0-9]*' }}  // Permite solo números                    
                  />
                  {errors.SUC_Comi && touched.SUC_Comi ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.SUC_Comi}
                    </Typography>
                  ) : null}
                </Grid>                    
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar Sucursal
                </Button>
            </Box>
          </Card>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha creado con exito la surcursal!
              </Alert>
          </Snackbar>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;