import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import Swal from 'sweetalert2';

import { Formik } from 'formik';
import { schemaServicesU } from '../../../shared/validators/Forms';

import {
  GET_SERV_STATUS,
  GET_SERV_TIPO,
  GET_MTD_PAGO,
  GET_DATA_SUCURSAL,
  GET_TURNOS_DISPONIBLES,
  GET_BARRIOS,
  GET_Turnos_Origen
} from '../../../graphql/Selects';
import { NEW_SERVICES } from '../../../graphql/Services';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import LoadingSpiner from '../../../components/LoadingSpiner';
import ModalItem from '../ModalItemServices';
import CustomShowingItem from '../ItemServices';
import { NewFormatDateLong, TurnoHours, MapeoSucursales } from '../../../Commons';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {},
  goBackButton: {
    marginTop: '1%',
    marginRight: '1%'
  },
  displayNone: {
    display: 'none'
  },
  displayBlock: {
    display: 'block'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const itemSession = JSON.parse(sessionStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, loadingState] = useState(true);
  const [countRow, setCountRow] = useState([])
  const [showingRow, setShowingRow] = useState([])
  const [fecha, setFecha] = useState('');
  const [fechaHr, setFechaHr] = useState('');
  const [errorFecha, setErrorFecha] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [horas, setHoras] = useState('');
  const [barrio, setBarrio] = useState('');
  const [isDisabledReferencia, setIsDisabledReferencia] = useState(true);
  const [aNafta, setANafta] = useState(false);
  const [errorSucursal, setErrorSucursal] = useState('');
  const [errorForm, setErrorForm] = useState([]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  
  const handleModal = () => {
    setOpenModal(!openModal);    
  };
  const handleModal1 = () => {    
    setButtonClicked(true); // Deshabilita el botón al abrir el modal
  };

  const goBackButton = () => {
    history.push('services')
  }

  const handleChangeBarrio = (e) => {
    setBarrio(e.target.value)
    if (getLabelBarrio(e.target.value).label === 'Otro') {
      setIsDisabledReferencia(false)
    } else {
      setIsDisabledReferencia(true)
    }
  }

  const handleChangeFecha = (e) => {
    let newFechaHoy = moment.utc()
    if (moment(newFechaHoy).isAfter(e.target.value)) {
      // setErrorFecha('La fecha ingresada no puede ser de dias anteriores')
    } else {
      setErrorFecha('');
    }
    setFecha(e.target.value);
    if (horas !== '') {
      setFechaHr(e.target.value + ` ${horas}`)
      setFechaFin(moment(e.target.value + ` ${horas}`).add(7199, 's').format('YYYY-MM-DD HH:mm:ss'))
    }
    if (sucursal !== '') {
      loadTurnos({ variables: { data: { fecha: NewFormatDateLong(e.target.value), Sucursal: sucursal } } })
    }
    setInitialValues(prevState => ({
      SERV_Fecha: fecha,
      SERV_Sucursal: prevState.SERV_Sucursal,
      SERV_Cliente: prevState.SERV_Cliente,
      SERV_Telefono: prevState.SERV_Telefono,
      SERV_Email: prevState.SERV_Email,
      SERV_Direccion: prevState.SERV_Direccion,
      SERV_Estado: prevState.SERV_Estado,
      SERV_Referencia: prevState.SERV_Referencia,
      SERV_TUR_Id: prevState.SERV_Turno,
      SERV_Barrio: prevState.SERV_Barrio,
      SERV_ANafta: prevState.SERV_ANafta,
      items: prevState.items,
    }))
  };

  const handleChangeSucursal = e => {
    if (e.target.value !== '') {
      setSucursal(e.target.value)
      getBarrios({ variables: { data: { Sucursal: e.target.value } } })
      getMtdPago({ variables: { data: { Sucursal: e.target.value } } })
      loadTurnos({ variables: { data: { fecha: NewFormatDateLong(fecha), Sucursal: e.target.value } } })
    } else {
      setErrorSucursal('Debe seleccionar una sucursal valida')
    }
  }

  const handleChangeANafta = e => {
    setANafta(!aNafta)
  }

  const handleChangeHour = e => {
    setHoras(e.target.value)
    setFechaHr(fecha + ` ${e.target.value}`)
    setFechaFin(moment(fecha + ` ${e.target.value}`).add(7199, 's').format('YYYY-MM-DD HH:mm:ss'))
    setInitialValues(prevState => ({
      SERV_Fecha: prevState.SERV_Fecha,
      SERV_Sucursal: prevState.SERV_Sucursal,
      SERV_Cliente: prevState.SERV_Cliente,
      SERV_Telefono: prevState.SERV_Telefono,
      SERV_Email: prevState.SERV_Email,
      SERV_Direccion: prevState.SERV_Direccion,
      SERV_Estado: prevState.SERV_Estado,
      SERV_Hrser: e.target.value,
      SERV_Referencia: prevState.SERV_Referencia,
      SERV_Turno: prevState.SERV_Turno,
      SERV_Barrio: prevState.SERV_Barrio,
      SERV_ANafta: prevState.SERV_ANafta,
      items: prevState.items,
    }))
  }

  const deleteItemServices = (i) => {
    const newCountRow = countRow.filter((value, theIndex) => { return i !== theIndex });
    const newShowingRow = showingRow.filter((value, theIndex) => { return i !== theIndex });
    setCountRow(newCountRow);
    setShowingRow(newShowingRow);
  };

  const sendModalData = (data) => {
    let object = {
      SERV_Servicio: data.SERV_Servicio,
      SERV_Importe: Number(data.SERV_Importe),
      SERV_Mtd_pago: data.SERV_Mtd_pago,
    }
    let { label: servTipo } = datasServTipo.find(item => item.value === data.SERV_Servicio)
    let { label: mtdopago } = datasMtdPago.find(item => item.value === data.SERV_Mtd_pago)
    let objectShowing = {
      SERV_Servicio: servTipo,
      SERV_Importe: Number(data.SERV_Importe),
      SERV_Mtd_pago: mtdopago,
    }
    let some = [...countRow, object]
    setCountRow([...countRow, object])
    setShowingRow([...showingRow, objectShowing])
    setInitialValues(prevState => ({
      SERV_Fecha: prevState.SERV_Fecha,
      SERV_Sucursal: prevState.SERV_Sucursal,
      SERV_Cliente: prevState.SERV_Cliente,
      SERV_Telefono: prevState.SERV_Telefono,
      SERV_Email: prevState.SERV_Email,
      SERV_Direccion: prevState.SERV_Direccion,
      SERV_Estado: prevState.SERV_Estado,
      SERV_Referencia: prevState.SERV_Referencia,
      SERV_Turno: prevState.SERV_Turno,
      SERV_Barrio: prevState.SERV_Barrio,
      SERV_ANafta: prevState.SERV_ANafta,
      items: some,
    }))
    handleModal()
  }

  const [initialValues, setInitialValues] = useState({
    SERV_Fecha: new Date().toISOString().substring(0, 10),
    SERV_Sucursal: '',
    SERV_Cliente: '',
    SERV_Telefono: '',
    SERV_Email: '',
    SERV_Direccion: '',
    SERV_Estado: '',
    SERV_Hrser: '08:00',
    SERV_Referencia: '',
    SERV_Turno: '',
    SERV_Barrio: '',
    SERV_Nafta: false,
    SERV_ANafta: '',
    SERV_Observacion: '',
    items: [],
  })

  const [dataServStatus, setDataServStatus] = useState([])
  const [datasServTipo, setDatasServTipo] = useState([])
  const [datasMtdPago, setDatasMtdPago] = useState([])
  const [datasSucursal, setDatasSucursal] = useState([])
  const [datasBarrios, setDatasBarrios] = useState([])
  const [dataturnosorigen, setDataTurnoOrigen] = useState([])
  //Valida que el boton se desactiva al darle click
  const [buttonClicked, setButtonClicked] = useState(false);
  

  const { data: dataStatus, loading: loading1, error: error1 } = useQuery(GET_SERV_STATUS);
  const { data: dataServTipo, loading: loading2, error: error2 } = useQuery(GET_SERV_TIPO);

  const [getMtdPago, { data: dataMtdPago, loading: loading3, error: error3 }] = useLazyQuery(GET_MTD_PAGO, {
    fetchPolicy: "no-cache"
  });

  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);

  const [getBarrios, { data: dataBarrios, loading: loading5, error: error5 }] = useLazyQuery(GET_BARRIOS, {
    fetchPolicy: "no-cache"
  });
  const {data: datatorigen, loading: loading6, error:error6 } = useQuery(GET_Turnos_Origen)
  
  const [sendServices] = useMutation(NEW_SERVICES, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data.createService.Message !== 'No se puede agregar este servicio en este bloque horario') {
        setCountRow([])
        setOpen(true)
        loadingState(false)
        setTimeout(() => {
          history.push({
            pathname: '/app/services',
            state: { isRefetch: true },
          });
        }, 1000)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.createService.Message
        })
      }
    }
  })
  const [loadTurnos, { loading: loadingTurno, data: dataTurnos }] = useLazyQuery(GET_TURNOS_DISPONIBLES, {
    fetchPolicy: "no-cache"
  });

  const getLabelBarrio = (id) => {
    let response
    dataBarrios.GetBarrios.map(item => {
      if (item.ZON_Id === id) {
        response = { label: item.ZON_Barrio, value: item.ZON_Id }
      }
      return item
    })
    return response
  };

  
  //Funcion para enviar datos al backend
  const sendData = async values => {
    loadingState(true);
    //Limpio los errores anteriores
    setErrorForm({});
    //Creo el objeto a validar
    let data = {
      SERV_Fecha: NewFormatDateLong(fechaHr),
      SERV_FechaFin: NewFormatDateLong(fechaFin),
      SERV_Sucursal: sucursal,
      SERV_Cliente: values.SERV_Cliente,
      SERV_Telefono: String(values.SERV_Telefono),
      SERV_Email: values.SERV_Email,
      SERV_Direccion: values.SERV_Direccion,
      SERV_Referencia: values.SERV_Referencia,      
      SERV_Estado: values.SERV_Estado,
      SERV_TUR_Id: values.SERV_Turno,
      SERV_Barrio: getLabelBarrio(barrio),
      SERV_Usuario: itemSession.USR_Id,
      items: countRow,
      SERV_Nafta: aNafta,      
      SERV_ANafta: aNafta === true ? values.SERV_ANafta : 0,
      SERV_Observacion: values.SERV_Observacion
    }
    //Valido con promesa
    schemaServicesU
      .validate(data)
      //Si es valido se envia el formulario
      .then(async res => {
        await sendServices({ variables: { data } });
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch(err => {
        loadingState(false);
        let object = {};
        object[err.path] = err.errors;
        setErrorForm(object);
      });
  };

  useEffect(() => {
    if (dataStatus && loading1 === false && error1 === undefined) {
      let thing = []
      dataStatus.GetDataServStatus.map(item => {
        return thing.push({ label: item.STATUS_Nombre, value: item.STATUS_Id })
      })
      setDataServStatus(thing)
    }

    if (dataServTipo && loading2 === false && error2 === undefined) {
      let thing = []
      dataServTipo.GetDataServTipo.map(item => {
        return thing.push({ label: item.TIPO_Nombre, value: item.TIPO_Id })
      })
      setDatasServTipo(thing)
    }

    if (dataMtdPago && loading3 === false && error3 === undefined) {
      let thing = []
      dataMtdPago.GetDatamtdpago.map(item => {
        return thing.push({ label: item.CTA_Nombre, value: item.SSM_CTA_Id })
      })
      setDatasMtdPago(thing)
    }

    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
    
    if (dataBarrios && loading5 === false && error5 === undefined) {
      let thing = []
      dataBarrios.GetBarrios.map(item => {
        return thing.push({ label: item.ZON_Barrio, value: item.ZON_Id })
      })
      setDatasBarrios(thing)
    }
    if (dataturnosorigen && loading6 === false && error6 === undefined) {
      let thing = []
      datatorigen.GetTurnoOrigenSys.map(item => {
        return thing.push({ label: item.TUR_Nombre, value: item.TUR_Id })
      })
      setDataTurnoOrigen(thing)
    }
    if (dataTurnos && loadingTurno === false) {
      if (dataTurnos.GetTurnos.Message !== 'valido') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: dataTurnos.GetTurnos.Message
        })
      }
    }
    if (loadingTurno === false && loading1 === false && loading2 === false && loading3 === false && loading4 === false && loading5 === false && loading6 === false) {
      loadingState(false)
    }
  }, [dataStatus,
    dataServTipo,
    dataMtdPago,
    dataSucursal,
    dataBarrios,
    loading1,
    loading2,
    loading3,
    loading4,
    loading5,
    loading6,
    error1,
    error2,
    error3,
    error4,
    error5,
    error6,
    loading,
    loadingTurno,
    dataTurnos
  ])
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await sendData(values);
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                className={classes.headerStyle}
                xs={12}
              >
                <CardHeader
                  subheader="Genera una nueva orden de servicio"
                  title="Servicios"
                />

                <Button
                  className={classes.goBackButton}
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={goBackButton}
                >
                  Atras
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Fecha de servicio:"
                    type="date"
                    name="SERV_Fecha"
                    onChange={handleChangeFecha}
                    onBlur={handleBlur}
                    value={fecha}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errorForm.SERV_Fecha && touched.SERV_Fecha ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Fecha}
                    </Typography>
                  ) : null}
                </Grid >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Hrser"
                    label="Hora de servicio:"
                    onBlur={handleBlur}
                    value={horas}
                    onChange={handleChangeHour}
                  >
                    {!!TurnoHours && (TurnoHours.map((option) => (
                      <MenuItem key={`status_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_Hrser && touched.SERV_Hrser ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Hrser}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Turno"
                    label="Origen del turno:"
                    onBlur={handleBlur}
                    value={values.SERV_Turno}
                    onChange={handleChange}
                  >
                    {!!dataturnosorigen && (dataturnosorigen.map((option) => (
                      <MenuItem key={`TUR_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                    
                  </TextField>
                  {errorForm.SERV_Turno && touched.SERV_Turno ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Turno}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Estado"
                    label="Estado servicio:"
                    onBlur={handleBlur}
                    value={values.SERV_Estado}
                    onChange={handleChange}
                  >
                    {!!dataServStatus && (dataServStatus.map((option) => (
                      <MenuItem key={`status_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_Estado && touched.SERV_Estado ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Estado}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre cliente:"
                    name="SERV_Cliente"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SERV_Cliente}
                  />
                  {errorForm.SERV_Cliente && touched.SERV_Cliente ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Cliente}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Numero de teléfono:"
                    name="SERV_Telefono"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.SERV_Telefono}
                  />
                  {errorForm.SERV_Telefono && touched.SERV_Telefono ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Telefono}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Correo electronico:"
                    name="SERV_Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    value={values.SERV_Email}
                  />
                  {errorForm.SERV_Email && touched.SERV_Email ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Email}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Dirección:"
                    name="SERV_Direccion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.SERV_Direccion}
                  />
                  {errorForm.SERV_Direccion && touched.SERV_Direccion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Direccion}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Sucursal"
                    label="Sucursal:"
                    onBlur={handleBlur}
                    value={sucursal}
                    onChange={handleChangeSucursal}
                  >
                    {!!datasSucursal && (datasSucursal.map((option) => (
                      <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorSucursal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorSucursal}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Barrio"
                    label="Barrio / Zona / Ciudad:"
                    onBlur={handleBlur}
                    value={barrio}
                    onChange={handleChangeBarrio}
                  >
                    {!!datasBarrios && (datasBarrios.map((option) => (
                      <MenuItem key={`rol_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_Barrio && touched.SERV_Barrio ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Barrio}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled={isDisabledReferencia}
                    label="Barrio / Zona / Ciudad:"
                    name="SERV_Referencia"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.SERV_Referencia}
                  />
                  {errorForm.SERV_Referencia && touched.SERV_Referencia ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Referencia}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    className={aNafta === true ? classes.displayBlock : classes.displayNone}
                    label="Nafta adicional:"
                    name="SERV_ANafta"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.SERV_ANafta}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    // className={aNafta === true ? classes.displayBlock : classes.displayNone}
                    label="Observación:"
                    name="SERV_Observacion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.SERV_Observacion}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >

                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                      Nafta Adicional
                    </Typography>
                    <Switch size="small" name="SERV_Nafta" checked={aNafta} onChange={handleChangeANafta} />
                  </Box>
                </Grid>
                <Divider />
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  {showingRow && showingRow.length > 0 ? (
                    <CustomShowingItem deleteItemServices={deleteItemServices} ifDel={true} editItemServices={console.log('some')} ifEdit={false} showingRow={showingRow} />
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={2}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={handleModal}
                    >
                      Servicio
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      // onClick={handleModal1}
                      disabled={buttonClicked}
                    >
                      Guardar servicio
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>

          </Card>

          <Divider />

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha creado con exito el servicio!
            </Alert>
          </Snackbar>
          <LoadingSpiner estado={loading} />
          <ModalItem
            estado={openModal}
            handleClose={handleModal}
            datasServTipo={datasServTipo}
            datasMtdPago={datasMtdPago}
            sendModalData={sendModalData}
          />
        </form>
      )
      }

    </Formik >
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;