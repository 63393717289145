import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import TablaServicios from './ServiceTable';
import Toolbar from './Toolbar';
import { ALL_SERVICES } from '../../graphql/Services';
import { useLazyQuery } from '@apollo/react-hooks';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'  
  }
}));

const Services = () => {
  const classes = useStyles();
  const [sucursalSearch, SetSucursalSearch] = useState('')
  const handlerChangeSuc = (e) => {
    SetSucursalSearch(e.target.value)
  }
  const onClickFunction = () => {
    if(sucursalSearch !== '') {
      searchData({variables: {data: {sucursal: sucursalSearch}}})
    }
  }
  const [searchData,{ loading: loading_Services, error: error_Services, data: servicesData, refetch }] = useLazyQuery(ALL_SERVICES,{
    fetchPolicy: "no-cache",
  })
  return (
    <Page
      className={classes.root}
      title="Servicios"
    >
      <Container maxWidth="lg">
        <Toolbar onClickFunction={onClickFunction} valueSuc={sucursalSearch} handlerChangeSuc={handlerChangeSuc} className={classes.spaceButtom}  />
        <Grid container spacing={3}>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <TablaServicios 
              loading_Services={loading_Services}
              error_Services={error_Services}
              servicesData={servicesData}
              sucursalSearch={sucursalSearch}
              refetch={refetch} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Services;