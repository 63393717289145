import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';

import { EDIT_MTDPAGO } from '../../../../graphql/Mtdpago';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const itemSession = JSON.parse(sessionStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);

  const initialValues = {
    MTD_Nombre: location.state.MTD_Nombre,
    MTD_Comision: location.state.MTD_Comision,
    MTD_Activo: location.state.MTD_Activo,
    MTD_Id: location.state.MTD_Id
    
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [sendMtdPago] = useMutation(EDIT_MTDPAGO, {
    onCompleted: data => {
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/MtdPago',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({      
        MTD_Nombre: Yup.string().max(25).required('Campo requerido!'),
        MTD_Comision: Yup.string().max(3).required('Campo requerido!')
      })}
      onSubmit={async (values, actions) => {
        await sendMtdPago({
          variables: {
            data: {
              MTD_Nombre: values.MTD_Nombre,
              MTD_Comision: values.MTD_Comision,
              MTD_Activo: values.MTD_Activo !== false ? 1 : 0,
              MTD_Id: values.MTD_Id,
              MTD_ByModify: itemSession.USR_Id,
            },            
          }
        })
        actions.resetForm({
          values: {
            MTD_Nombre: '',
            MTD_Comision: '',
          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Editar un nuevo Metodo de pago"
              title="Metodo de Pago"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >                
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre Metodo de pago"
                    name="MTD_Nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.MTD_Nombre}                    
                  />
                  {errors.MTD_Nombre && touched.MTD_Nombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.MTD_Nombre}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Comisión"
                    name="MTD_Comision"                    
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.MTD_Comision}                    
                  />
                  {errors.MTD_Comision && touched.MTD_Comision ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.MTD_Comision}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid 
                  item
                  md={12}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                        Activo
                    </Typography>
                    <Switch size="small" name="MTD_Activo" checked={values.MTD_Activo} onChange={handleChange} />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar Metodo
                </Button>
            </Box>
          </Card>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha creado con exito el Metodo de pago!
              </Alert>
          </Snackbar>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;