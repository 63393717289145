import { gql } from 'apollo-boost';

//query

export const GET_MTD_PAGO = gql`
query GetMtdPago{
    GetMtdPago{
        CTA_Id
        CTA_Nombre        
        CTA_FModify
        CTA_Activo
        CTA_Tipo
        CTA_ByModify
        CTA_OrdenBy
    }
}
`;

//mutation
export const NEW_MTDPAGO = gql`
    mutation createMtdPago($data: MtdPagoInput){
        createMtdPago(data:$data){
            MTD_Nombre
            MTD_Comision
            MTD_ByModify
        }
    }
`;

export const EDIT_MTDPAGO = gql`
  mutation EditMtdpago($data: MtdPagoInputEdit){
    EditMtdpago(data: $data){
        MTD_Nombre
        MTD_Comision
        MTD_ByModify
        MTD_Activo
    }
  }
`;