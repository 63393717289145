import React from 'react';
import {
    Typography,
    Grid,
    CardContent
} from '@material-ui/core';

const ItemContent = (props) => {
    
    return (
        <CardContent key={props.i}>
            <Grid
                container
                spacing={1}
            >
                {/* <Grid
                    item
                    md={12}
                    xs={12}
                >
                    <Typography variant="body2" component="p" >
                        Por hacer: {props.i + 1}
                    </Typography>
                </Grid> */}
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                        Metodo de pago:
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="body2" component="p">
                        {props.row.MTD_Nombre}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                        Tipo de servicio:
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="body2" component="p">
                        {props.row.TIPO_Nombre}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="body2" component="p" style={{ fontWeight: 'bold' }}>
                        Importe:
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="body2" component="p">
                        {`$ ${(props.row.SERV_SID_Importe).toFixed(2)}`}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    )
}

export default ItemContent;