import React, {useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import LoadingSpiner from '../../components/LoadingSpiner';
import { useMutation } from '@apollo/react-hooks';
import { NEW_USER_UPDATE } from '../../graphql/Login';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const RegisterView = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  //Estado para los errores que salgan en el formulario
  const [errorForm , setErrorForm] = useState('');
  //Estado para el spiner de carga
  const [loading , setLoading] = useState(false);
  //Constate para definir las validaciones del fomulario
  const newUserId = sessionStorage.getItem('newUserId');
  const validationSchema = Yup.object().shape({
    Password: Yup.string()
      .matches(uppercaseRegex, 'Un caracter Mayuscula requerido!')
      .matches(lowercaseRegex, 'Un caracter Minuscula requerido!')
      .matches(numericRegex, 'Un numero requerido!')
      .min(8, 'Minimo 8 caracteres requeridos')
      .required('Es requerido una Contraseña'),
    Re_password: Yup.string()
      .oneOf([Yup.ref('Password')], 'Constraseña no coinciden')
      .required('Es requerido una Contraseña'),
  })
  //Mutation de envio de los datos al backend
  const [sendUser] = useMutation(NEW_USER_UPDATE, {
    onCompleted: data => {
      setLoading(false);
      if (data) {
        setTimeout(() => {
          history.push({
            pathname: '/',
          });
        }, 1000)
      }
    }
  })
  //Funcion para enviar datos al backend
  const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm('')
    //Creo el objeto a validar
    let data = {
      Password: values.Password,
      Re_password: values.Re_password,
      USR_Id: newUserId
    }
    //Valido con promesa
    validationSchema.validate(data)
      //Si es valido se envia el formulario
      .then(async (res) => {
        setLoading(false);
        console.log("data_",data)
        await sendUser({ variables: { data } })
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch((err) => {
        setLoading(false);
        setErrorForm(err.errors[0])
      })


  }
  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              Password: '',
              Re_password: '',
            }}
            onSubmit={async (values) => {
              console.log('DEBUG', values);
              return sendData(values)
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Cambio de clave
                  </Typography>
                  {/* <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Por cuenta nueva
                  </Typography> */}
                </Box>
                {errorForm ? (
                  <Box mb={2}>
                    <Typography
                      color="error"
                      variant="h6"
                    >
                      {errorForm}
                    </Typography>
                  </Box>
                ) : null}
                <TextField
                  error={Boolean(touched.Password && errorForm)}
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.Password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.Re_password && errorForm)}
                  fullWidth
                  label="Repite Password"
                  margin="normal"
                  name="Re_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.Re_password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Actualizar
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <LoadingSpiner estado={loading} />
    </Page>
  );
};

export default RegisterView;
