import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  makeStyles
} from '@material-ui/core';

import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '30%',
    backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(2, 4, 3),
    top: '5%',
    left: '35%',
    outline: 'none'
  },
}));

export default function ModalItem(props) {
  const classes = useStyles();
  
  const body = (
    <Card className={classes.paper}>
      <CardContent>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
          >
            <img alt='imagen recibo' height={500} src={props.content} />
          </Grid>
        </Grid >
      </CardContent>
    </Card>

  );

  return (
    <div>
      <Modal
        open={props.estado}
        onClose={props.openModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}