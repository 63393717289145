import { gql } from 'apollo-boost';

//query

export const GET_ALLINVENTARIO = gql`
query GetInventario($data: GetDatFilterInput){
    GetInventario(data: $data){
        AF_Id
        AF_FechaCompra
        SUC_Nombre
        AF_Nombre
        AF_Modelo
        AF_Serial
        AF_Asignada
        AF_Proveedor
        AF_Factura
        AF_Costo
        AF_Sucursal
        AF_ByModify
    }
}
`;

//mutation

export const NEW_INVENTARIO = gql`
mutation createInventario($data: InventarioInput){
    createInventario(data:$data){
        AF_FechaCompra
        AF_Sucursal
        AF_Nombre
        AF_Modelo
        AF_Serial
        AF_Asignada
        AF_Proveedor
        AF_Factura
        AF_Costo
    }
}
`;

export const EDIT_INVENTARIO = gql`
mutation editInventario($data: InventarioInput){
    editInventario(data:$data){
        AF_Id
        AF_FechaCompra
        AF_Sucursal
        AF_Nombre
        AF_Modelo
        AF_Serial
        AF_Asignada
        AF_Proveedor
        AF_Factura
        AF_Costo
    }
}
`;