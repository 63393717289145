import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  // Typography,
  makeStyles
} from '@material-ui/core';

import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    width: 380,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    left: '3vw',
    [theme.breakpoints.up('lg')]: {
      top: '25vh',
      left: '21vw',
      width: 800,
    },
    outline: 'none'
  },
}));

export default function ModalItem(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.estado);
  const [data, setData] = React.useState({
    SERV_Importe: 0,
    SERV_Mtd_pago: '',
    SERV_Servicio: ''
  });
  const [isEdit, setIsEdit] = React.useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  const sendData = () => {
    props.sendModalData(data)
    setData({
      SERV_Importe: 0,
      SERV_Mtd_pago: '',
      SERV_Servicio: ''
    })
  }

  React.useEffect(() => {
    setOpen(props.estado)
    if (props.dataEdit) {
      if (props.dataEdit.indice >= 0) {
        setData(props.dataEdit)
        setIsEdit(true)
      } else {
        setData(props.dataEdit)
        setIsEdit(false)
      }
    }
  }, [props])

  const body = (
    <Card className={classes.paper}>
      {isEdit ? (
        <CardHeader
          subheader="Editar items de servicios"
          title="Servicios"
        />
      ) : (
        <CardHeader
          subheader="Agregar items de servicios"
          title="Servicios"
        />
      )
      }
      <Divider />
      <CardContent>

        <Grid
          container
          spacing={3}
        >

          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              name='SERV_Servicio'
              fullWidth
              select
              required
              value={data.SERV_Servicio}
              onChange={handleChange}
              label="Tipo de servicio"
            >
              {!!props.datasServTipo && (props.datasServTipo.map((option) => (
                <MenuItem key={`serv_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem >
              )))}
            </TextField>

          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              name='SERV_Mtd_pago'
              fullWidth
              select
              required
              value={data.SERV_Mtd_pago}
              onChange={handleChange}
              label="Metodo pago"
            >
              {!!props.datasMtdPago && (props.datasMtdPago.map((option) => (
                <MenuItem key={`mtd_${option.value}`} value={option.value}>
                  {option.label}
                </MenuItem >
              )))}
            </TextField>

          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              name='SERV_Importe'
              type="number"
              fullWidth
              required
              value={data.SERV_Importe}
              onChange={handleChange}
              label="Importe"
            />
            {/* {!!rowErrors.SERV_Importe && rowTouched.SERV_Importe ? (
          <Typography
            color="error"
            variant="subtitle2">
            {rowErrors.SERV_Importe}
          </Typography>
        ) : null} */}
          </Grid>



        </Grid >
        <Box
          display="flex"
          justifyContent="flex-end"
          pt={3}
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={sendData}
          >
            {isEdit ?
              'Editar' : 'Agregar'
            }
          </Button>
        </Box>
      </CardContent>
    </Card>

  );

  return (
    <div>
      <Modal
        open={open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}