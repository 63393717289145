import React from 'react';
import {
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Page from 'src/components/Page';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import Toolbar from '../BackToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'  
  }
}));

const VerUsuarios = () => {
  const classes = useStyles();
  const location = useLocation();
  console.log(location.state)
  const data = location.state;
  return (
    <Page
      className={classes.root}
      title="Editando Usuario"
    >
      <Container maxWidth="xl">
      <Toolbar className={classes.spaceButtom}  />   
        <Card>
          <CardHeader
          title={`Usuario ID: ${data.USR_Id} `}
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Nombre completo:
                    </Typography>
              </Grid >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.USR_NombreCom}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Email:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.USR_Email}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Telefono:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.USR_Telefono}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Dirección:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.USR_Direccion}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Rol:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.UGP_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Login:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.USR_Id}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default VerUsuarios;