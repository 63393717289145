import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

export const ItemListComponent = ({title, value}) => (
  <ListItem>
    <ListItemText
      primary={title}
    />
    <ListItemSecondaryAction>
      ${value ? value.toFixed(2) : '0.00'}
    </ListItemSecondaryAction>
  </ListItem>
)

export const ItemListComponentNormalValue = ({title, value}) => (
  <ListItem>
    <ListItemText
      primary={title}
    />
    <ListItemSecondaryAction>
      {value ? value : '0'}
    </ListItemSecondaryAction>
  </ListItem>
)