import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Typography,
  List,
  Card,
  CardHeader,
  CardContent,
  Divider,
  colors,
  makeStyles,
  Grid
} from '@material-ui/core';
import { GET_SERVICIOS_TURNOSREALIZADO } from "../../../graphql/Dashboard";
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { ItemListComponentNormalValue } from '../../../components/ItemListComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
  },
  titleBox: {
    marginLeft: '20px',
    fontSize: '15px',
  },
  dividerMargin: {
    marginTop: '15px',
    marginLeft: '30px',
  },
  media: {
    height: 140,
    overflow: 'auto',
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '10%',
    marginLeft: '3%',
  },
}));

const TurnosAgendados = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const location = useLocation();
  const { loading: loading_Services, error: error_Services, data: servicesData } = useQuery(GET_SERVICIOS_TURNOSREALIZADO, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetServicioTurnosRealizados.map(item => {
        return thing.push(item)
      })
      setDataTable(thing);
    }
  }, [servicesData, error_Services, loading_Services, location])


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CardHeader className={classes.title} title="Turnos Realizados" />
        </Grid>
        <Divider />
        <Grid item className={classes.headerStyle} xs={12}>
          <Typography className={classes.titleBox} variant="h6">
            Sucursal
          </Typography>
          <Typography className={classes.titleBox} variant="h6">
            Turnos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CardContent className={classes.media}>
            <div>
              <List dense={true}>
                {!!dataTable && dataTable.map((item) => <ItemListComponentNormalValue 
                title={item.SUC_Nombre} value={`${item.Confirmado}/${item.TotalServicios}`} />)}
              </List>
            </div>
          </CardContent>
          <Divider className={classes.dividerMargin} />
        </Grid>
      </Grid>
    </Card>
  );
};

TurnosAgendados.propTypes = {
  className: PropTypes.string
};

export default TurnosAgendados;
