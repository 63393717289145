import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { GET_ALLUSUARIOS } from "../../graphql/Usuarios";
import {Visibility, Edit} from "@material-ui/icons";
import {RowsxList} from '../../Commons'
const columns = [
  { field: 'id', headerName: 'Usuario', width: 130 },
  //{ field: 'USR_Sucursal', headerName: 'Sucursal', width: 130 },
  { field: 'USR_Descripcion', headerName: 'Descripcion', width: 130 },
  { field: 'USR_Telefono', headerName: 'Telefono', width: 130 },

];

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
  }
}));

const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const location = useLocation();
  const { loading: loading_Services, error: error_Services, data: servicesData, refetch } = useQuery(GET_ALLUSUARIOS)
  const handleClick = (tipo,data) => {
    if(tipo === 'edit'){
      history.push({
        pathname: 'EditUsuario',
        state: data
      })
    } else if (tipo === 'delete'){
      // history.push({
      //   pathname: 'EditUsuario',
      //   state: data
      // })
      // handleDelete(data);
    }
    else {
      history.push({
        pathname: 'verUsuario',
        state: data
      })
    }
  }
  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetUsuarios.map(item => {
        item['id'] = item.USR_Id;
        return thing.push(item)
      })
      setDataTable(thing);
    }
    if(location){
      if(location.state != null){
        if(location.state.isRefetch)
        refetch()
      }
    }
  }, [servicesData, error_Services, loading_Services, location, refetch])

  // const handleDelete = async (data) => {
  //   try {
  //     await deleteUsuario({ variables: { id: data.id } }); // Llama a la mutación para eliminar usuario
  //     refetch(); // Vuelve a cargar los datos después de eliminar
  //   } catch (error) {
  //     console.error('Error deleting user:', error);
  //   }
  // };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Usuarios" />
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (                    
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                <TableCell className={classes.head}>
                  Ver
                </TableCell>
                <TableCell className={classes.head}>
                  Modificar
                </TableCell>
                {/* <TableCell className={classes.head}>
                  Eliminar
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order,i) => {
                return (
                  <TableRow style={ i % 2? { background : "#f4f6f8" }:{ background : "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>                          
                      );
                    })}
                    <TableCell> 
                      <Visibility onClick={() => handleClick('ver',order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit',order)} />
                    </TableCell>
                    {/* <TableCell>
                      <Edit onClick={() => handleClick('delete',order)} />
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;