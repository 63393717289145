import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {},
    delButton: {
        backgroundColor: '#ff4444',
        color: '#ffffff'
    },
}));
export default function FormDialog(props) {
    const classes = useStyles();
    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose} maxWidth={'sm'} aria-labelledby="dialog-opcion">
                <DialogTitle id="dialog-opcion"></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Que desea hacer?
                        <br />
                        {props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleEdit} variant="contained" color="primary">
                        Editar
                    </Button>
                    <Button onClick={props.handleVer} variant="contained" color="primary">
                        Ver
                    </Button>
                    <Button onClick={props.handleDel} variant="contained" className={classes.delButton}>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
