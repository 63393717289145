import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import { DEL_SUCURSAL, GET_SUCURSALES } from "../../../graphql/Sucursales";
import { useHistory, useLocation } from 'react-router-dom';
import { Visibility,Edit,Delete } from '@material-ui/icons';
import {RowsxList} from '../../../Commons'



const columns = [
  { field: 'some', headerName: '', width: 130 },  
  { field: 'Descripcion', headerName: 'Nombre', width: 260 },
  // { field: 'SUC_Nafta', headerName: 'SUC_Nafta', width: 130 },

];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
  }
}));

const Sucursal = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])  
  const history = useHistory();
  const { loading: loading_Services, error: error_Services, data: servicesData, refetch } = useQuery(GET_SUCURSALES)
  const location = useLocation();
  

  const handleClick = (tipo,data) => {
    if(tipo === 'edit'){
      history.push({
        pathname: 'editSucursal',
        state: data
      })
    } else if(tipo === 'ver') {
      history.push({
        pathname: 'verSucursal',
        state: data
      })
    }else{
      // history.push({
      //   pathname: 'delSucursal',
      //   state: data
      // })
      // delSucursal()
    }
    }
   
  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetSucursales.map(item => {
        item['id'] = item.SUC_Id;
        item['Descripcion'] = item.SUC_Nombre;   
        return thing.push(item)
      })
      setDataTable(thing);
    }
    if(location){
      if(location.state != null){
        if(location.state.isRefetch)
        refetch()
      }
    }
  }, [servicesData, error_Services, loading_Services, location, refetch])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Sucursales" />
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                  <TableCell className={classes.head} >
                  Ver
                  </TableCell>
                  <TableCell className={classes.head} >
                  Modificar
                  </TableCell>
                  {/* <TableCell className={classes.head} >
                  Borrar
                  </TableCell>                   */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order,i) => {
                return (
                  <TableRow style={ i % 2? { background : "#f4f6f8" }:{ background : "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell> 
                      <Visibility onClick={() => handleClick('ver',order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit',order)} />
                    </TableCell>
                    {/* <TableCell>
                      <Delete onClick={() => handleClick('del',order)} />
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default Sucursal;