import React from 'react';
import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import {
    makeStyles
} from '@material-ui/core';
const useStyles = makeStyles(() => ({
    root: {},
    colorTableA: {
        color: "Black",
        background: "#d4eaff",
        textAlign: "center",
    },
    colorTableC: {
        color: "white",
        background: "red",
        textAlign: "center",
    },
    colorTableP: {
        color: "Black",
        background: "#ffffc0",
        textAlign: "center",
    },
    colorTableR: {
        color: "white",
        background: "#FE9747",
        textAlign: "center",
    }
}));


const localizer = momentLocalizer(moment) // or globalizeLocalizer

const MyCalendar = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Calendar
                localizer={localizer}
                step={60}
                messages={{
                    next: "Siguiente",
                    previous: "Atrás",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Dia"
                }}
                onSelectEvent={event => props.handleClick(event)}
                defaultView='week'
                showMultiDayTimes
                events={props.events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                eventPropGetter={
                    (event) => {
                        let style = '';
                        switch (event.STATUS_Nombre) {
                            case 'Confirmado':
                                style = classes.colorTableA
                                break;
                            case 'Cancelado':
                                style = classes.colorTableC
                                break;
                            case 'Pendiente':
                                style = classes.colorTableP
                                break;
                            case 'Reprogramado':
                                style = classes.colorTableR
                                break;
                            default:
                                style = ''
                                break;
                        }

                        return {
                            className: style,
                        };
                    }
                }
            />
        </div>
    )
}

export default MyCalendar;