import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  Grid,
  TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import { GET_DATA_SUCURSAL } from '../../graphql/Selects'
import { useQuery } from '@apollo/react-hooks';
import { MapeoSucursales } from '../../Commons';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const Toolbar = (props, { className, ...rest }) => {
  const classes = useStyles();
  const [sucursalesSelect, setSucursalesSelect] = useState([]);
  const handleChangeHere = e => {
    props.handlerChangeSuc(e)
  }
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  useEffect(() => {
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setSucursalesSelect(thing)
    }
  }, [dataSucursal, loading4, error4])
  // console.log(sucUser)
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          className={classes.headerStyle}
          md={6}
          xs={12}
        >
          <TextField
            fullWidth
            select
            name="SERV_Sucursal"
            label="Sucursal"
            value={props.valueSuc}
            onChange={handleChangeHere}
          >
            {!!sucursalesSelect && (sucursalesSelect.map((option) => (
              <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                {option.label}
              </MenuItem >
            )))}
          </TextField>
          <Button
            color="primary"
            variant="contained"
            onClick={props.onClickFunction}
          >
            Buscar
          </Button>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            {/* <Button className={classes.importButton}>
              Import
            </Button>
            <Button className={classes.exportButton}>
              Export
            </Button> */}
            <RouterLink to='/app/newService'>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<AddIcon />}
              >
                Nuevo Servicio
              </Button>
            </RouterLink>
          </Box>
        </Grid>

      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
