import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  ListItem,
  // ListItemIcon,
  // ListItemText,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import StarBorder from '@material-ui/icons/StarBorder';
import InboxIcon from '@material-ui/icons/MoveToInbox';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

export const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
      </Button>
    </ListItem>
  );
};

export const NavItemDropdown = ({
  className,
  title,
  items,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        button
      >
        <Button
          activeClassName={classes.active}
          className={classes.button}
          onClick={handleClick}
        >
          <InboxIcon
            className={classes.icon}
            size="20"
          />
          <span className={classes.title}>
            {title}
          </span>
        </Button>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};


          // <ListItem button onClick={handleClick}>
          //   <ListItemIcon>
          //     <InboxIcon />
          //   </ListItemIcon>
          //   <ListItemText primary="Inbox" />
          //   {open ? <ExpandLess /> : <ExpandMore />}
          // </ListItem>
          // <Collapse in={open} timeout="auto" unmountOnExit>
          //   <List component="div" disablePadding>
          //     <ListItem button className={classes.nested}>
          //       <ListItemIcon>
          //         <StarBorder />
          //       </ListItemIcon>
          //       <ListItemText primary="Starred" />
          //     </ListItem>
          //   </List>
          // </Collapse>