import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Switch from '@material-ui/core/Switch';

import { EDIT_ADDSERVICIOS } from '../../../../graphql/AddServicios';
import {  useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const itemSession = JSON.parse(sessionStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);

  const initialValues = {
    TIPO_Nombre: location.state.TIPO_Nombre,
    TIPO_Activo: location.state.TIPO_Activo !== 0 ? true : false,
    TIPO_Id: location.state.TIPO_Id
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
 
  const [sendMtdPago] = useMutation(EDIT_ADDSERVICIOS, {
    onCompleted: data => {
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/AddServicios',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({      
        TIPO_Nombre: Yup.string().max(25).required('Campo requerido!'),        
      })}
      onSubmit={async (values, actions) => {
        await sendMtdPago({
          variables: {
            data: {
              TIPO_Nombre: values.TIPO_Nombre,
              TIPO_Activo: values.TIPO_Activo !== false ? 1 : 0,
              TIPO_Id: values.TIPO_Id,
              TIPO_ByModify: itemSession.USR_Id,            
            },            
          }
        })
        actions.resetForm({
          values: {
            TIPO_Nombre: '',
            
          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Editar servicios"
              title="Servicios"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >                
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Servicio"
                    name="TIPO_Nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TIPO_Nombre}                    
                  />
                  {errors.TIPO_Nombre && touched.TIPO_Nombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.TIPO_Nombre}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid 
                  item
                  md={12}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                        Activa
                    </Typography>
                    <Switch size="small" name="TIPO_Activo" checked={values.TIPO_Activo} onChange={handleChange} />
                  </Box>
                </Grid>                       
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar Servicio
                </Button>
            </Box>
          </Card>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha creado con exito la servicio!
              </Alert>
          </Snackbar>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;