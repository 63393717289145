import { gql } from 'apollo-boost';

export const GET_ALL_SERVICIOS = gql`
         query GetVentaDxSucursal($data: InputsDate) {
           GetVentaDxSucursal(data: $data) {
             servicios {
               SERV_SID_Id
               SERV_SID_Importe
               SERV_SID_ImporteNeto
               CTA_Tipo
               TIPO_Nombre
               MTD_Nombre
               SERV_Fecha
               SERV_Nafta
               SERV_ANafta
             }
             depositos {
               DP_Id
               DP_Fecha
               DP_Sucursal
               DP_Usuario
               DP_MtdDepo
               DP_Referencia
               DP_Monto
               DP_Recibo
               MTD_Nombre
               CTA_Nombre
             }
             gastos {
               GAST_Fecha
               GAST_Sucursal
               GAST_Descripcion
               GAST_Importe
               GAST_Recibo
               GAST_Id
               GAST_NumFact
               MTD_Nombre
             }
             sucData {
               SUC_Id
               SUC_Nombre
               SUC_Activo
               SUC_ByModify
               SUC_FechaM
               SUC_Nafta
               SUC_Comi
               SUC_Orden
               SUC_Vista
             }
           }
         }
       `;

export const GET_ALL_GASTOS = gql`
query GetAllGastos($data: InputsDate){
    GetAllGastos(data:$data){  
        GastosAll{
            GAST_Id
            GAST_Fecha
            GAST_Sucursal
            GAST_Descripcion
            MTD_Nombre
            GAST_Importe            
            GAST_NumFact
        }
    }
}
`;

export const GET_ALL_TurAddUser = gql`
query GetAllTurAddUser($data: InputsDateAllUser){
    GetAllTurAddUser(data:$data){ 
      AllTurAddUser{
            USR_Id
            SUC_Nombre
            Turnos
          }         
    }
}
`;

export const GET_ALL_SELLaBILL = gql `
query GetAllSellaBill($data: InputsDateSaB){
      GetAllSellaBill(data: $data){
          AllSellaBill{
              SERV_Sucursal
              SUC_Nombre
              SERV_Estado
              SERV_Delete
              SERV_Fecha
              Venta_del_dia
              Comision
              Gastos
              Deposito
              Diferencia
            }
          }
        }
`;

