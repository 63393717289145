import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles
} from '@material-ui/core';
// import {NewFormatDateShort} from '../../../Commons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import { GET_PARAMETROS } from "../../../graphql/Parametros";
import { useHistory, useLocation } from 'react-router-dom';
import { Visibility,Edit } from '@material-ui/icons';

const columns = [
  { field: 's1', headerName: '', width: 130 },
  { field: 's', headerName: '', width: 130 },
  { field: 'id', headerName: 'Item', width: 160 },
  { field: 'Descripción', headerName: 'Descripción', width: 130 },
  { field: 'String', headerName: 'String', width: 130 },
  { field: 'Number', headerName: 'Number', width: 130 },

];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
  }
}));

const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const { loading: loading_Services, error: error_Services, data: servicesData, refetch } = useQuery(GET_PARAMETROS)
  const location = useLocation();

  const handleClick = (tipo,data) => {
    if(tipo === 'edit'){
      history.push({
        pathname: 'EditParametros',
        state: data
      })
    } else {
      history.push({
        pathname: 'VerParametros',
        state: data
      })
    }
  }
  
  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetParametros.map(item => {
        item['id'] = item.PAR_Id;
        item['Descripción'] = item.PAR_Descripcion;
        item['String'] = item.PAR_String;   
        item['Number'] = item.PAR_Number;
        return thing.push(item)
      })
      setDataTable(thing);
    }
    if(location){
      if(location.state != null){
        if(location.state.isRefetch)
        refetch()
      }
    }
  }, [servicesData, error_Services, loading_Services, location, refetch])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Parametros del sistema" />
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                  <TableCell className={classes.head} >
                  Ver
                  </TableCell>
                  <TableCell className={classes.head} >
                  Modificar
                  </TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order,i) => {
                return (
                  <TableRow style={ i % 2? { background : "#f4f6f8" }:{ background : "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell> 
                      <Visibility onClick={() => handleClick('ver',order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit',order)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;