import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';
import Toolbar from './BackToolbar';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { GET_ALL_GASTOS} from "../../graphql/Report";
import { GET_DATA_SUCURSAL } from "../../graphql/Selects";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  NewFormatDateShort,
  getNafta,  
  getSumTotalServSocio,
  getComision,
  getGastoAyudante,  
  getTotalARendirSocio,
  getSumTotalDepo,
  MapeoSucursales
} from '../../Commons'
import MenuItem from '@material-ui/core/MenuItem';

const columns = [
  { field: 'GAST_Id', headerName: 'ID', width: 130 },
  { field: 'GAST_Fecha', headerName: 'Fecha', width: 130 },
  { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'MTD_Nombre', headerName: 'Tipo de Pago', width: 130 },
  { field: 'GAST_Descipcion', headerName: 'Descripción', width: 130 },
  { field: 'GAST_Importe', headerName: 'Importe', width: 130 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));


const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const [datasSucursal, setDatasSucursal] = useState();
  const { data: dataSucursal, loading, error } = useQuery(GET_DATA_SUCURSAL);
  const [findSales] = useLazyQuery(GET_ALL_GASTOS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setDataTable(data.GetAllGastos)
    }
  })

  useEffect(() => {
    if (dataSucursal && loading === false && error === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
  }, [dataTable, dataSucursal, loading, error])

  return (
    <Page
      className={classes.root}
      title="Usuario"
    >
      <Formik
        initialValues={{
          FechaIni: '',
          FechaFin: '',          
        }}
        onSubmit={async (values) => {

          await findSales({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin,
                
              },
            }
          })

        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">
            <Toolbar className={classes.spaceButtom} />
            <CardContent>
              <Card>
                <CardHeader
                  title="Reporte de Gastos"
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"                          
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaIni && touched.FechaIni ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaIni}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"                          
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaFin && touched.FechaFin ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaFin}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          select
                          name="SERV_Sucursal"
                          label="Sucursal"
                          onBlur={handleBlur}
                          value={values.SERV_Sucursal}
                          onChange={handleChange}                         
                        >
                          {!!datasSucursal && (datasSucursal.map((option) => (
                            <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                              {option.label}
                            </MenuItem >
                          )))}
                        </TextField>
                        {errors.SERV_Sucursal && touched.SERV_Sucursal ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.SERV_Sucursal}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
              </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          {columns.map((rowsName) => (
                            <TableCell align="center" className={classes.head} key={rowsName.field}>
                              {rowsName.headerName}
                            </TableCell>

                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!dataTable.servicios && (dataTable.servicios.map((row, i) => (
                          <TableRow key={dataTable.servicios.length + i + '-servicios'}>
                            <TableCell align="center">{`S${row.SERV_SID_Id}`}</TableCell>
                            <TableCell align="center">{NewFormatDateShort(row.SERV_Fecha)}</TableCell>
                            <TableCell align="center">{(row.sumaTotal.toFixed(2))}</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">{row.MTD_Nombre}</TableCell>
                          </TableRow>
                        )))}
                        {!!dataTable.gastos && (dataTable.gastos.map((row, i) => (
                          <TableRow key={dataTable.gastos.length + i + '-gastos'}>
                            <TableCell align="center">{`G${row.GAST_Id}`}</TableCell>
                            <TableCell align="center">{NewFormatDateShort(row.GAST_Fecha)}</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">{(row.GAST_Importe.toFixed(2))}</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">{row.MTD_Nombre}</TableCell>
                          </TableRow>
                        )))}
                        {!!dataTable.depositos && (dataTable.depositos.map((row, i) => (
                          <TableRow key={dataTable.depositos.length + i + '-deposito'}>
                            <TableCell align="center">{`D${row.DP_Id}`}</TableCell>
                            <TableCell align="center">{NewFormatDateShort(row.DP_Fecha)}</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">{(row.DP_Monto.toFixed(2))}</TableCell>
                            <TableCell align="center">{row.MTD_Nombre}</TableCell>
                          </TableRow>
                        )))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-end"
            >
              <Grid
                item
                md={6}
                xs={12}
              >

                <CardContent>
                  <Card>
                    <Divider />
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="spanning table">
                        <TableBody>
                          <TableRow>
                            <TableCell rowSpan={6} />
                            <TableCell >Subtotal Ventas</TableCell>
                            <TableCell align="right">{getSumTotalServSocio(dataTable.servicios)}</TableCell>
                          </TableRow>                          
                          <TableRow>
                            <TableCell >Nafta</TableCell>
                            <TableCell align="right">{getNafta(dataTable.servicios,'abada')}</TableCell>
                          </TableRow>                        
                          <TableRow>
                            <TableCell >Gastos</TableCell>
                            <TableCell align="right">{getGastoAyudante(dataTable.gastos, 'Gasto')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell >Depositos</TableCell>
                            <TableCell align="right">{getSumTotalDepo(dataTable.depositos)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell >Comisión (30%)</TableCell>
                            <TableCell align="right">{getComision(dataTable.servicios)}
                              </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell >Rendir a MAM</TableCell>
                            <TableCell align="right">{getTotalARendirSocio(
                                                      getSumTotalServSocio(dataTable.servicios),
                                                      getComision(dataTable.servicios),
                                                      getSumTotalDepo(dataTable.depositos),
                                                      getGastoAyudante(dataTable.gastos, 'Gasto'))
                            }</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </CardContent>
              </Grid>

            </Grid>


          </Container>

        )}
      </Formik>
    </Page>
  );
}


export default DataTable;
