import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  Button,
  Grid,
  // TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';


const useStyles = makeStyles(() => ({
  root: {},
  customButtonDel: {
    color: 'white',
    backgroundColor: '#bd1414b0',
    '&:hover': {
      backgroundColor: 'white',
      color: '#bd1414b0'
    },
    padding: '8px 16px', // Ajusta el tamaño del botón según tus preferencias
    fontSize: '14px' // Ajusta el tamaño de la fuente del botón según tus preferencias
  },
  customButtonEdi: {
    color: 'white',
    backgroundColor: '#1cad1ac7',
    '&:hover': {
      backgroundColor: 'white',
      color: '#1cad1ac7'
    },
    padding: '8px 16px', // Ajusta el tamaño del botón según tus preferencias
    fontSize: '14px' // Ajusta el tamaño de la fuente del botón según tus preferencias
  }
}));

const CustomShowingItem = (props) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        subheader="Detalles del servicio"        
      />
      <Divider />
      {!!props.showingRow && (props.showingRow.map((row, i) => {
        return (
          <CardContent key={i}>
            <Grid container spacing={2}>
              <Grid item md={10} xs={12} >
                {/* <Typography variant="body2" component="p">
                  Item Nro: {i + 1}
                </Typography> */}
              </Grid>
              {!!props.ifDel && (
                <Grid
                  item
                  md={2}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {/* <Button
                      className={classes.customButtonEdi}
                      startIcon={<EditIcon />}
                      onClick={e => { props.editItemServices(i)}}
                      
                    >
                      Editar item
                    </Button> */}
                    <Button
                      className={classes.customButtonDel}
                      startIcon={<DeleteIcon />}
                      onClick={e => {props.deleteItemServices(i)}}
                    >
                      {/* Borrar item */}
                    </Button>
                  </Box>
                </Grid> )}
              {!!props.ifEdit && (
                <Grid
                  item
                  md={2}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      className={classes.customButtonEdi}
                      startIcon={<EditIcon />}
                      onClick={e => { props.editItemServices(i)}}
                    >
                      {/* Editar item */}
                    </Button>
                    
                    {/* <Button
                      className={classes.customButtonDel}
                      startIcon={<DeleteIcon />}
                      onClick={e => { props.deleteItemServices(i)}}
                    >
                      Eliminar
                    </Button> */}
                  </Box>
                </Grid> )}
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Metodo de pago:
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {row.SERV_Mtd_pago}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Tipo de servicio:
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {row.SERV_Servicio}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Importe:
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {`$${(row.SERV_Importe).toFixed(2)}`}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        )
      }))}
    </Card>
  )

}

export default CustomShowingItem;