import { gql } from 'apollo-boost';

export const GET_SERV_STATUS = gql`
  query GetDataServStatus{
    GetDataServStatus{
      STATUS_Id
      STATUS_Nombre
    }
  }
`;

export const GET_SERV_TIPO = gql`
  query GetDataServTipo{
    GetDataServTipo{
      TIPO_Id
      TIPO_Nombre
    }
  }
`;

export const GET_MTD_PAGO = gql`
  query GetDatamtdpago($data: BarriosData){
    GetDatamtdpago(data: $data){
      SSM_CTA_Id
      CTA_Nombre
    }
  }
`;
export const GET_MTD_PAGO_DEPOSITO = gql`
  query GetDatamtdpagoDeposito($data: BarriosData){
    GetDatamtdpagoDeposito(data: $data){
      SSM_CTA_Id
      CTA_Nombre
    }
  }
`;
export const GET_MTD_PAGO_GASTO = gql`
  query GetDatamtdpagoGasto($data: BarriosData){
    GetDatamtdpagoGasto(data: $data){
      SSM_CTA_Id
      CTA_Nombre
    }
  }
`;

export const GET_MTD_PAGO_DEPOSITO_NEW = gql`
  query GetDatamtdpagoDeposito1($data: BarriosData){
    GetDatamtdpagoDeposito1(data: $data){
      CTA_Id
      CTA_Nombre
    }
  }
`;
export const GET_MTD_PAGO_GASTO_NEW = gql`
query GetDatamtdpagoDeposito1($data: BarriosData){
    GetDatamtdpagoDeposito1(data: $data){
      CTA_Id
      CTA_Nombre
    }
  }
`;

export const GET_DATA_SUCURSAL = gql`
  query GetDataSucursal{
    GetDataSucursal{
      SUC_Id
      SUC_Nombre
    }
  }
`;

export const GET_TURNOS_DISPONIBLES = gql`
  query GetTurnos($data: TurnoData){
    GetTurnos(data: $data){
      Message
    }
  }
`;

export const GET_Turnos_Origen = gql`
  query GetTurnoOrigenSys{
    GetTurnoOrigenSys{
      TUR_Id
      TUR_Nombre
    }
  }
`;

export const GET_BARRIOS = gql`
  query GetBarrios($data: BarriosData){
    GetBarrios(data: $data){
      ZON_Id
      SUC_Nombre
      ZON_Barrio
    }
  }
`;