import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { switchAuth } from '../../Commons';
import { Footer } from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = (props) => {
  let history = useHistory();
  let nombreChild = props.viewName
  let auth = JSON.parse(sessionStorage.getItem('sesTime'));
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    function validateSome() {
      if (auth && (auth.USR_UGP_ID === 0 || auth.USR_UGP_ID === 1 || auth.USR_UGP_ID === 2)) return true
      let dataSwitch = switchAuth(nombreChild, auth && auth.USR_UGP_ID);
      if (typeof dataSwitch !== 'string') {
        if (!dataSwitch) history.push('/');
      } else {
        history.push(`${dataSwitch}`);
      }
    }

    validateSome()
  }, [nombreChild, auth, history])
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {props.children}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
