import { gql } from 'apollo-boost';

//query

export const GET_LEGAJO = gql`
query GetLegajo($data: GetDatFilterInput){
    GetLegajo(data: $data) {
        FIC_Id
        FIC_Dni
        FIC_FIngreso
        FIC_TContrato
        FIC_Fecha_Nac
        FIC_NombreCom
        FIC_Mail
        FIC_Telefono
        FIC_Celular
        FIC_Direccion
        FIC_Cpostal
        FIC_Nacionalidad
        FIC_EstCivil
        FIC_TUser
        FIC_Sucursal
        SUC_Nombre
        FIC_Vregistro
        FIC_ENombre
        FIC_ETelefono
        FIC_SeNombre
        FIC_TSeguro
        FIC_Poliza
        FIC_Observacion
        FIC_ByModify
    }
}
`; 


//mutation

export const NEW_LEGAJO = gql`
mutation createLegajo($data: LegajoInput){
    createLegajo(data:$data){                
        FIC_Dni
        FIC_FIngreso
        FIC_TContrato
        FIC_Fecha_Nac
        FIC_NombreCom
        FIC_Mail
        FIC_Telefono
        FIC_Celular
        FIC_Direccion
        FIC_Cpostal
        FIC_Nacionalidad
        FIC_EstCivil
        FIC_TUser
        FIC_Sucursal        
        FIC_Vregistro
        FIC_ENombre
        FIC_ETelefono
        FIC_SeNombre
        FIC_TSeguro
        FIC_Poliza
        FIC_Observacion
        FIC_ByModify
    }
}
`;

