import { gql } from "apollo-boost";

export const GET_USERS = gql`
  query Users{
    Users{
      USR_Id
      USR_NombreCom
      USR_Descripcion
      USR_inCambio
      USR_Password
      USR_ultPwdChange
      USR_UGP_ID
      USR_Disabled
      USR_Direccion
      USR_Telefono
      USR_Email
      USR_CreatedDate
      USR_ModifiedDate
      USR_ModifiedBy
      USR_Active
    }
  }
`;

export const LOGIN_USER = gql`
  query UserValidate($data: LoginInput){
    UserValidate(data: $data){
      USR_Id
      USR_NombreCom
      USR_UGP_ID
      USR_Disabled
      USR_Active
      UGP_Nombre
      message
      parametros {
        PAR_Id
        PAR_Descripcion
        PAR_Type
        PAR_String
        PAR_Number
      }
      sucursales {
        SSU_Id
        SSU_SUC_Id
        SSU_USR_Id
      }
    }
  }
`;

export const NEW_USER_UPDATE = gql`
  mutation updateNewUser($data: newUserUpdate){
    updateNewUser(data: $data){
      USR_Id
      USR_NombreCom
      USR_UGP_ID
      USR_Disabled
      USR_Active
      UGP_Nombre
      message
      parametros {
        PAR_Id
        PAR_Descripcion
        PAR_Type
        PAR_String
        PAR_Number
      }
    }
  }
`

