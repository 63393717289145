import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import {
  GET_DATA_SUCURSAL,
  GET_MTD_PAGO_DEPOSITO
} from '../../../graphql/Selects';
import { EDIT_DEPOSITO } from '../../../graphql/Deposito';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { MapeoSucursales, NewFormatDateShort } from '../../../Commons';
import { schemaDeposito } from '../../../shared/validators/Forms';
import LoadingSpiner from '../../../components/LoadingSpiner';
import ImageUploader from 'react-images-upload';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(() => ({
  root: {},
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  buttonGoBack: {
    marginTop: '1%',
    marginRight: '1%',
  },
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  // const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [img, setImg] = useState('');
  const [renderingTime, setRenderingTime] = useState(0);
  const [suc, setSucursal] = useState(location.state.DP_Sucursal);

  const goBackButton = () => {
    history.push('depositos')
  }
  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImg(pictureDataURLs[0]);
  }

  const handleChangeSuc = e => {
    if (e.target.value !== '') {
      setSucursal(e.target.value);
      loadMtdPago({ variables: { data: { Sucursal: e.target.value } } });
    }
  };

  const [initialValues, setInitialValues] = useState({
    DP_Fecha: '',
    DP_Monto: '',
    DP_MtdDepo: '',
    DP_Sucursal: '',
    DP_Referencia: ''
  })

  const setValuesForm = () => {
    setInitialValues(
      {
        DP_Fecha: NewFormatDateShort(location.state.DP_Fecha),
        DP_Monto: location.state.DP_Monto,
        DP_MtdDepo: location.state.DP_MtdDepo,
        DP_Sucursal: location.state.DP_Sucursal,
        DP_Referencia: location.state.DP_Referencia
      }
    )
    loadMtdPago({ variables: { data: { Sucursal: location.state.DP_Sucursal } } });
    setRenderingTime(1)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [datasSucursal, setDatasSucursal] = useState([])
  const [datasMtdPago, setDatasMtdPago] = useState([])
  const [
    loadMtdPago,
    { data: dataMtdPago, loading: loading3, error: error3 }
  ] = useLazyQuery(GET_MTD_PAGO_DEPOSITO, {
    fetchPolicy: 'no-cache'
  });
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  const [sendDeposito] = useMutation(EDIT_DEPOSITO, {
    onCompleted: data => {
      if (data) {
        setOpen(true)
        setTimeout(() => {
          history.push({
            pathname: '/app/depositos',
            state: { isRefetch: true },
          });
        }, 1000)

      }
    }
  })
  //Funcion para enviar datos al backend
  const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({})
    console.log(values)
    //Creo el objeto a validar
    let data = {
      DP_Id: location.state.DP_Id,
      DP_MtdDepo: values.DP_MtdDepo,
      DP_Fecha: values.DP_Fecha,
      DP_Referencia: values.DP_Referencia,
      DP_Sucursal: suc,
      DP_Monto: String(values.DP_Monto),
      DP_Recibo: img,
      DP_LastRecibo: location.state.DP_Recibo,
    }
    //Valido con promesa
    schemaDeposito.validate(data)
      //Si es valido se envia el formulario
      .then(async (res) => {
        await sendDeposito({ variables: { data } })
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch((err) => {
        setLoading(false);
        let object = {}
        object[err.path] = err.errors
        setErrorForm(object)
      })

  }
  useEffect(() => {
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
    if (dataMtdPago && loading3 === false && error3 === undefined) {
      let thing = []
      dataMtdPago.GetDatamtdpagoDeposito.map(item => {
        return thing.push({ label: item.CTA_Nombre, value: item.CTA_Id });
      });
      setDatasMtdPago(thing)
    }
    if (loading4 === false && loading3 === false) {
      setLoading(false);
      if (renderingTime === 0) {
        setValuesForm();
      }
    }
  }, [dataSucursal,
    loading4,
    error4,
    location,
    dataMtdPago,
    loading3,
    error3
  ])
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await sendData(values)
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                className={classes.headerStyle}
                xs={12}
              >
                <CardHeader
                  subheader={`Modificando Deposito ID: ${location.state.DP_Id}`}
                  title="Deposito"
                />
                <Button
                  className={classes.buttonGoBack}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={goBackButton}
                >
                  Atras
              </Button>
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Fecha de deposito"
                    type="date"
                    name="DP_Fecha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.DP_Fecha}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errorForm.DP_Fecha && touched.DP_Fecha ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.DP_Fecha}
                    </Typography>
                  ) : null}
                </Grid >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    select
                    name="DP_Sucursal"
                    label="Sucursal"
                    onBlur={handleBlur}
                    value={suc}
                    onChange={handleChangeSuc}
                  >
                    {!!datasSucursal && (datasSucursal.map((option) => (
                      <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.DP_Sucursal && touched.DP_Sucursal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.DP_Sucursal}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Forma de deposito"
                    name="DP_MtdDepo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.DP_MtdDepo}
                    select
                  >
                    {!!datasMtdPago && (datasMtdPago.map((option) => (
                      <MenuItem key={`mtd_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.DP_MtdDepo && touched.DP_MtdDepo ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.DP_MtdDepo}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Concepto"
                    name="DP_Referencia"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.DP_Referencia}
                  />
                  {errorForm.DP_Referencia && touched.DP_Referencia ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.DP_Referencia}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Monto del deposito"
                    name="DP_Monto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.DP_Monto}
                  />
                  {errorForm.DP_Monto && touched.DP_Monto ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.DP_Monto}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <ImageUploader
                    withIcon={true}
                    defaultImages={img !== '' ? [img] : [location.state.DP_Recibo]}
                    label="Archivo de max: 5mb, acepta jpg, png, jpeg"
                    buttonText='Seleccione imagenes'
                    singleImage={true}
                    onChange={onDrop}
                    imgExtension={['.jpg', '.png','.jpeg']}
                    maxFileSize={5242880}
                    withPreview={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Actualizar
                </Button>
            </Box>

          </Card>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha editado con exito el Deposito!
              </Alert>
          </Snackbar>
          <LoadingSpiner estado={loading} />
        </form>
      )}
    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;