import { gql } from 'apollo-boost'

//Query
export const GET_ALL_GASTOS = gql `
  query GetGastos($data: GetDatFilterInput){
    GetGastos(data: $data){
      GAST_Id
      GAST_Fecha
      SUC_Nombre
      GAST_User
      GAST_Mtdpago
      MTD_Nombre
      GAST_Descripcion
      GAST_Importe
      GAST_Recibo
      GAST_NumFact
      GAST_Sucursal
    }
  }
`;


//mutation
export const NEW_GASTO = gql`
  mutation createGasto($data: GastoInput){
    createGasto(data: $data){
    GAST_Fecha
    GAST_Sucursal
    GAST_Descripcion
    GAST_Importe
    GAST_Recibo
    GAST_Id
    GAST_NumFact
    }
  }
`;

export const EDIT_GASTO = gql`
  mutation editGasto($data: GastoInput){
    editGasto(data: $data){
    GAST_Sucursal
    GAST_Descripcion
    GAST_Importe
    GAST_Id
    GAST_NumFact
    }
  }
`;