import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
// import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import Switch from '@material-ui/core/Switch';

import { EDIT_PARAMETROS } from '../../../../graphql/Parametros';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));
const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const initialValues = {
    PAR_Descripcion: location.state.PAR_Descripcion,
    PAR_Content: location.state.PAR_Number !== null ? location.state.PAR_Number : location.state.PAR_String,
    PAR_Type: location.state.PAR_Type,
    PAR_Id: location.state.PAR_Id
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [sendMtdPago] = useMutation(EDIT_PARAMETROS, {
    onCompleted: data => {
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/Parametros',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({      
        PAR_Descripcion: Yup.string().max(25).required('Campo requerido!'),
        PAR_Content: Yup.string().max(3).required('Campo requerido!')
      })}
      onSubmit={async (values, actions) => {
        await sendMtdPago({
          variables: {
            data: {
              PAR_Descripcion: values.PAR_Descripcion,
              PAR_Type: values.PAR_Type,
              PAR_Id: values.PAR_Id,
              PAR_Content: values.PAR_Content,
            },            
          }
        })
        actions.resetForm({
          values: {
            PAR_Content: '',
            PAR_Descripcion: '',
            PAR_Type: '',
            PAR_Id: '',
          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Editar un parametro de sistema"
              title="Editar parametro"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >                
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled
                    label="Descripción"
                    name="PAR_Descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.PAR_Descripcion}
                    
                  />
                  {errors.PAR_Descripcion && touched.PAR_Descripcion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.PAR_Descripcion}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Parametro"
                    name="PAR_Content"                    
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.PAR_Content}                    
                  />
                  {errors.PAR_Content && touched.PAR_Content ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.PAR_Content}
                    </Typography>
                  ) : null}
                </Grid>                
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Guardar Parametro
                </Button>
            </Box>
          </Card>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha guardado con exito el parametro!
              </Alert>
          </Snackbar>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;