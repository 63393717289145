import { gql } from 'apollo-boost';

//query

export const GET_ALLDEPOSITOS = gql`
  query GetDepositos($data:GetDatFilterInput){
    GetDepositos(data: $data){
      DP_Id
      DP_Fecha
      DP_Sucursal
      DP_MtdDepo
      SUC_Nombre
      DP_Usuario
      MTD_Nombre
      DP_Referencia
      DP_Monto
      DP_Recibo
    }
  }
`;

//mutation

export const NEW_DEPOSITO = gql`
  mutation createDeposito($data: DepositoInput){
    createDeposito(data:$data){
      DP_Id
      DP_Fecha
      DP_Sucursal
      DP_Usuario
      DP_MtdDepo
      DP_Referencia
      DP_Monto
      DP_Recibo
    }
  }
`;

export const EDIT_DEPOSITO = gql`
  mutation editDeposito($data: DepositoInput){
    editDeposito(data: $data){
      DP_Id
      DP_Fecha
      DP_Sucursal
      DP_Usuario
      DP_MtdDepo
      DP_Referencia
      DP_Monto
      DP_Recibo
    }
  }
`;