import moment from 'moment';

const userPer = [
  {
    tipo: 3,
    vistas:['Servicios', 'NewServices', 'EditServices', 'VerServicios',
            'Gastos', 'NewGastos', 'VerGastos', 'EditGastos',
            'Depositos', 'NewDeposito', 'VerDepositos', 'EditDeposito',
            'Reportes', 'ListReport', 'DataTable', 
            'Inventario', 'NewInventario', 'EditInventario',
            'Configuración'],
    paths: ['services', 'newService', 'EditService', 'verServicio', 
            'gastos', 'newGasto', 'verGasto', 'EditGasto', 
            'depositos', 'newDeposito', 'verDeposito', 'editDeposito', 
            'reports', 'ReportSaleDay', 
            'inventario', 'NewInventario', 'EditInventario']
  },
  // {
  //   tipo: 4,
  //   vistas: ['Servicios', 'NewServices', 'EditServices', 'VerServicios'],
  //   paths: ['services', 'newService', 'EditService', 'verServicio']
  // },
  {
    tipo: 4,
    vistas: ['Servicios', 'NewServices', 'EditServices', 'VerServicios', 'Gastos', 'NewGastos', 'VerGastos', 'EditGastos', 'Depositos', 'NewDeposito', 'VerDepositos', 'EditDeposito', 'Reportes','ListReport','DataTable'],
    paths: ['services', 'newService', 'EditService', 'verServicio', 'gastos', 'newGasto', 'verGasto', 'EditGasto', 'depositos', 'newDeposito', 'verDeposito', 'editDeposito', 'reports', 'ReportSaleDay']
  },
  {
    tipo: 5,
    vistas: ['Reportes', 'ListReport', 'DataTable'],
    paths: ['reports', 'ReportSaleDay']
  },
  {
    tipo: 6,
    vistas: ['Servicios', 'NewServices', 'EditServices', 'VerServicios', 'Gastos', 'NewGastos', 'VerGastos', 'EditGastos', 'Depositos', 'NewDeposito', 'VerDepositos', 'EditDeposito', 'Reportes', 'ListReport', 'DataTable'],
    paths: ['services', 'newService', 'EditService', 'verServicio', 'gastos', 'newGasto', 'verGasto', 'EditGasto', 'depositos', 'newDeposito', 'verDeposito', 'editDeposito', 'reports', 'ReportSaleDay']
  },
  {
    tipo:7,
    vistas: ['Servicios','Reportes', 'ListReport', 'DataTable'],
    paths: ['services', 'reports', 'ReportSaleDay']
  }

]

// const allRoutes = ['Inicio', 'Servicios', 'Gastos', 'Depositos', 'Usuarios', 'Inventario', 'Reportes','Metodo pago','Sucursal','Agregar Servicios','Parametros','Tax','Barrios']
const allRoutes = ['Inicio', 'Servicios', 'Gastos', 'Depositos', 'Usuarios', 'Inventario', 'Reportes']

export const switchAuth = (nombreChild, auth) => {
  switch (auth) {
    case 1:
      return true;
    case 2:
      return true;
    case 3:
      if (userPer[0].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[0].paths[0]
      }
    case 4:
      if (userPer[1].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[1].paths[0]
      }
    case 5:
      if (userPer[2].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[2].paths[0]
      }
    case 6:
      if (userPer[3].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[3].paths[0]
      }
    case 7:
      if (userPer[4].vistas.indexOf(nombreChild) !== -1) {
        return true
      } else {
        return userPer[4].paths[0]
      }
    default:
      return false
  }
}

export const arrayFrom = (auth) => {
  switch (auth) {
    case 3:
      return userPer[0].vistas
    case 4:
      return userPer[1].vistas
    case 5:
      return userPer[2].vistas
    case 6:
      return userPer[3].vistas
    case 7:
      return userPer[4].vistas
    default:
      return allRoutes;
  }
}

export const getEstadoId = (nombre) => {
  let name = '0'
  switch (nombre) {
    case 'Confirmado':
      name = '2'
      break;
    case 'Pendiente':
      name = '1'
      break;
    case 'Reprogramado':
      name = '4'
      break;
    case 'Cancelado':
      name = '3'
      break;
    default:
      name = '1'
      break;
  }
  return name
}



// export function generarHoras(horaInicio, horaFinal, intervalo) {
//   // Convertir las horas de inicio y final a objetos de fecha
//   var inicio = new Date();
//   var final = new Date();
//   var opciones = [];

//   // Dividir las horas de inicio y final en horas y minutos
//   var inicioSplit = horaInicio.split(":");
//   var finalSplit = horaFinal.split(":");

//   // Establecer las horas y minutos de los
//   //objetos de fecha

//   inicio.setHours(parseInt(inicioSplit[0]));
//   inicio.setMinutes(parseInt(inicioSplit[1]));
//   final.setHours(parseInt(finalSplit[0]));
//   final.setMinutes(parseInt(finalSplit[1]));
  
//   // Validar que la hora de inicio sea menor que la hora final
//   if (inicio >= final) {
//   console.log("La hora de inicio debe ser menor que la hora final");
//   return;
//   }
  
//   // Crear una copia de la hora de inicio para iterar sobre ella
//   var horaActual = new Date(inicio);
  
//   // Iterar sobre el rango de horas y generar las opciones
//   while (horaActual < final) {
//   // Obtener la hora actual como cadena en formato hh:mm
//   var horaActualStr =
//   ("0" + horaActual.getHours()).slice(-2) +
//   ":" +
//   ("0" + horaActual.getMinutes()).slice(-2);

//   // Agregar la hora actual a las opciones
// opciones.push(horaActualStr);

// // Añadir el intervalo de tiempo a la hora actual
// horaActual.setMinutes(horaActual.getMinutes() + intervalo);

// }

// return opciones;
// }

export const TurnoHours = [
  { value: '07:00:00', label: '07:00:00' },
  { value: '07:30:00', label: '07:30:00' },
  { value: '08:00:00', label: '08:00:00' },
  { value: '08:30:00', label: '08:30:00' },
  { value: '09:00:00', label: '09:00:00' },
  { value: '09:30:00', label: '09:30:00' },
  { value: '10:00:00', label: '10:00:00' },
  { value: '10:30:00', label: '10:30:00' },
  { value: '11:00:00', label: '11:00:00' },
  { value: '11:30:00', label: '11:30:00' },
  { value: '12:00:00', label: '12:00:00' },
  { value: '12:30:00', label: '12:30:00' },
  { value: '13:00:00', label: '13:00:00' },
  { value: '13:30:00', label: '13:30:00' },
  { value: '14:00:00', label: '14:00:00' },
  { value: '14:30:00', label: '14:30:00' },
  { value: '15:00:00', label: '15:00:00' },
  { value: '15:30:00', label: '15:30:00' },
  { value: '16:00:00', label: '16:00:00' },
  { value: '16:30:00', label: '16:30:00' },
  { value: '17:00:00', label: '17:00:00' },
  { value: '17:30:00', label: '17:30:00' },
  { value: '18:00:00', label: '18:00:00' },
  { value: '18:30:00', label: '18:30:00' },
  { value: '19:00:00', label: '19:00:00' },
  { value: '19:30:00', label: '19:30:00' },
  { value: '20:00:00', label: '20:00:00' },  
]

export const GetEquipoInv = [
  { value: 'Aspiradora de liquido', label: 'Aspiradora de liquido' },
  { value: 'Aspiradora de polvo', label: 'Aspiradora de polvo' },
  { value: 'Aspiradora de polvo y liquido', label: 'Aspiradora de polvo y liquido' },
  { value: 'Pulverizador', label: 'Pulverizador' },
  { value: 'Taladro', label: 'Taladro' },
  { value: 'Vaporizadora', label: 'Vaporizadora' },
]

// export const getFromStore = (store, dato) => {
//   let storeF = store
//   switch (dato) {
//     case 'VNAFTA':
//       let { PAR_Number: PAR_NUMBER_VNAFTA } = storeF.find(item => item.PAR_Id === 'VNAFTA')
//       return PAR_NUMBER_VNAFTA
//     case 'COMI':
//       let { PAR_Number: PAR_NUMBER_COMI } = storeF.find(item => item.PAR_Id === 'COMI')
//       return PAR_NUMBER_COMI
//     case 'NTURNO':
//       let { PAR_Number: PAR_NUMBER_NTURNO } = storeF.find(item => item.PAR_Id === 'NTURNO')
//       return PAR_NUMBER_NTURNO
//     case 'ANAFTA':
//       let { PAR_Number: PAR_NUMBER_ANAFTA } = storeF.find(item => item.PAR_Id === 'ANAFTA')
//       return PAR_NUMBER_ANAFTA
//     default:
//       return store
//   }
// }

export function MapeoSucursales(dataSucursal, user = JSON.parse(sessionStorage.getItem('sucursalesUser'))) {
  let { UGP_Nombre } = JSON.parse(sessionStorage.getItem('sesTime'))
  let thing = []
  UGP_Nombre = UGP_Nombre.trim()
  if (UGP_Nombre === "Gerente" || UGP_Nombre === "Contador" || UGP_Nombre === "Develop" || UGP_Nombre === "Administrador") {
    dataSucursal.GetDataSucursal.map(item => {
      return thing.push({ label: item.SUC_Nombre, value: item.SUC_Id })
    })
  } else {
    user.map(item => {
      let finded = dataSucursal.GetDataSucursal.find((itemF, i) => itemF.SUC_Id === item.SSU_SUC_Id)
      // finded['SSU_Id'] = item.SSU_Id
      return thing.push({ label: finded.SUC_Nombre, value: finded.SUC_Id })
      //con el .find se obtienen solo los que cumplan la validacion
    })
  }
  return thing
}

export function NewFormatDateShort(dataDate) {
  let fecha = moment.utc(dataDate).format("YYYY-MM-DD")
  return fecha
}

export function NewFormatDateLong(dataDate) {
  let fecha = moment.utc(dataDate).format("YYYY-MM-DD HH:mm:ss")
  return fecha
}

export function NewFormatDateHour(dataDate) {
  let fecha = moment.utc(dataDate).format("HH:mm:ss")
  return fecha
}

// Registro para mostar
export const RowsxList = 20;

// Funcion cumple
export function calcularEdad(fecha) {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return edad;
}



//*************Reportes***********//

export function getNafta(allDatos) {
  // console.log(allDatos)
  let naftaTotal = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      naftaTotal += Number(allDatos[i].SERV_Nafta)
      // console.log("aaa" + naftaTotal)
    }
    return naftaTotal.toFixed(2)
  } else {
    return (0).toFixed(2)
  }

}

export function getANafta(allDatos) {
  // console.log(allDatos)
  let anaftaTotal = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      anaftaTotal += Number(allDatos[i].SERV_ANafta)
    }
    return anaftaTotal.toFixed(2)
  }
}

export function getComision(allDatos) {
  let comisionNeto = 0
  let comisionTotal = 0
  let comi = 0  
  if (allDatos && allDatos.length > 0){
    for (let i = 0; i < allDatos.length; i++){
      comisionTotal += Number(allDatos[i].SERV_SID_Importe)
      comisionNeto += Number(allDatos[i].SERV_SID_ImporteNeto)
      comi = Number(comisionTotal) - Number(comisionNeto)       
    }    
  }   
  if (comi === 0 ) {
    return (0).toFixed(2)
  }else {
    return comi.toFixed(2)
  }
  
}


export function getSumTotalServ(allDatos, tipo) {
  let sumTotal = 0
  let sumTotalElectronico = 0
  // console.log(allDatos)
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      if (allDatos[i].CTA_Tipo === 1) {
        sumTotal += Number(allDatos[i].SERV_SID_Importe)
      } else {
        sumTotalElectronico += Number(allDatos[i].SERV_SID_Importe)
      }

    }
  }
  if (tipo === 1) {
    return sumTotal.toFixed(2)
  } else {
    return sumTotalElectronico.toFixed(2)
  }  
}

export function getSumTotalDash(allDatos, tipo) {
  let sumTotal = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      if (tipo === 'Caja') {
        sumTotal += Number(allDatos[i].SERV_SID_ImporteNeto);
      } else {
        sumTotal += Number(allDatos[i].SERV_SID_Importe);
      }

    }
  }
  return sumTotal.toFixed(2)
}

export function getValorReal(allDatos, Comi){
  if( allDatos && allDatos.length > 0) {
    allDatos.map((item) => {
      // console.log(allDatos)
      let comiEmp = item.Ventasdelmes * Comi
      item.Ventasdelmes -= item.Nafta;
      item.Ventasdelmes -= comiEmp
      item.Ventasdelmes -= item.Resta
      item.Ventasdelmes -= item.Gasto
      // console.log(Comi)
      // console.log(item.Ventasdelmes)
      // console.log(item.Nafta)
      // console.log(item.Resta)
      // console.log(item.Gasto)
      // console.log(comiEmp)
      return item;
    })
    return allDatos;
  }
};

export function getSumTotalDepo(allDatos) {
  let DepoTotal = 0
  // let DepoTotalElectronico = 0
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {
      DepoTotal += Number(allDatos[i].DP_Monto)
      // console.log(DepoTotal)
    }
  }
  // console.log(DepoTotal.toFixed(2))
  return DepoTotal.toFixed(2)
}

export function getGastoAyudante(allDatos, tipo) {
  let ayudantesTotal = 0
  let gasto = 0
  if (allDatos && allDatos.length > 0) {
    let stringThing = ''
    for (let i = 0; i < allDatos.length; i++) {
      stringThing = allDatos[i].GAST_Descripcion
      if (stringThing.toUpperCase() === "PAGAR EMPLEADOS" || stringThing.toUpperCase() === "PAGAR AYUDANTE") {
        ayudantesTotal += Number(allDatos[i].GAST_Importe)
      } else {
        gasto += Number(allDatos[i].GAST_Importe)
      }
    }
  }
  if (tipo === 'Ayudante') {
    // console.log("ayudante " + ayudantesTotal.toFixed(2))
    return ayudantesTotal.toFixed(2)
  } else {
    // console.log("gasto " + gasto.toFixed(2))
    return gasto.toFixed(2)
  }
}

export function getTotalARendir(ventas, comisiones, Deposito,Nafta) {
  let totalARendir = 0
  let totalARendirRound = 0
  // console.log(ventas)
  // console.log(comisiones)
  // console.log(Deposito)
  if (ventas !== 0 && comisiones !== 0 && Deposito !== 0 && Nafta) {
    totalARendir = Number(ventas) - Number(comisiones) - Number(Deposito) - Number(Nafta)
  }
  return totalARendir.toFixed(2)
}

export function getDeducibles(Gastos, Ayudante, Comision) {
  let Deducibles = 0
    if ((Comision === NaN) ? 0 : Comision)
      // console.log(Comision)
  if (Gastos !== 0 && Ayudante !== 0 && Comision !== 0) {
    Deducibles =  Number(Gastos) + Number(Ayudante) + Number(Comision)
  }
  return Deducibles.toFixed(2)
}


/********Fin Reportes*********/

/********Fin Socio*********/

export function getSumTotalServSocio(allDatos) {
  let sumTotal = 0  
  if (allDatos && allDatos.length > 0) {
    for (let i = 0; i < allDatos.length; i++) {      
        sumTotal += Number(allDatos[i].SERV_SID_Importe)      
    }
  }  
    return sumTotal.toFixed(2)
}

export function getTotalARendirSocio(ventas, comisiones,  Gastos, Nafta) {
  let totalARendir = 0  
  // console.log(ventas)
  // console.log(comisiones)
  // console.log(Deposito)
  // console.log(Gastos)
  // console.log(Nafta)
  if (ventas !== 0 && comisiones !== 0 && Gastos !== 0 && Nafta !== 0) {
    totalARendir = (Number(ventas) - Number(comisiones) - Number(Gastos) - Number(Nafta))*0.15
  }  
  return totalARendir.toFixed(2)  
}

export function getTotalARendirSocio5(ventas, comisiones, Deposito, Gastos) {
  let totalARendir = 0  
  // console.log(ventas)
  // console.log(comisiones)
  // console.log(Deposito)
  if (ventas !== 0 && comisiones !== 0 && Deposito !== 0 && Gastos !== 0) {
    totalARendir = (Number(ventas) - Number(comisiones) - Number(Deposito) - Number(Gastos))*0.5
  }
    
  return totalARendir.toFixed(2)  
}
/********Fin Reportes Socio *********/