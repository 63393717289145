import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles,
  Grid,
  Box,
  Button
} from '@material-ui/core';
import { NewFormatDateShort, RowsxList } from '../../Commons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Visibility, Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';

const columns = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: 'Fecha', headerName: 'Fecha', width: 130 },
  // { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'DP_Usuario', headerName: 'Usuario', width: 130 },
  // { field: 'DP_MtdDepo', headerName: 'Mtdo Deposito', width: 130 },
  { field: 'DP_Referencia', headerName: 'Concepto', width: 130 },
  { field: 'Monto', headerName: 'Monto', width: 130 },
  // { field: 'DP_Recibo', headerName: 'Recibo', width: 130 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '1%',
    marginRight: '1%'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  }
}));

const DataTable = (props, { className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const location = useLocation();

  const handleClick = (tipo, data) => {
    if (tipo === 'edit') {
      history.push({
        pathname: 'editDeposito',
        state: data
      })
    } else {
      history.push({
        pathname: 'verDeposito',
        state: data
      })
    }
  }

  useEffect(() => {
    let thing = []
    if (props.servicesData && props.loading_Services === false && props.error_Services === undefined) {
      props.servicesData.GetDepositos.map(item => {
        item['id'] = item.DP_Id;
        item['Fecha'] = NewFormatDateShort(item.DP_Fecha);
        item['Monto'] = "$" + (item.DP_Monto).toFixed(2);
        return thing.push(item)
      })
      setDataTable(thing);
    }
  }, [props.servicesData, props.error_Services, props.loading_Services, location])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          className={classes.headerStyle}
          xs={12}
        >
          <CardHeader title="Depositos" />
          <RouterLink className={classes.buttonStyle} to='/app/newDeposito'>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              startIcon={<AddIcon />}
            >
              Nuevo Deposito
            </Button>
          </RouterLink>
        </Grid>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                <TableCell className={classes.head} >
                  Ver
                </TableCell>
                <TableCell className={classes.head} >
                  Modificar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, i) => {
                return (
                  <TableRow style={i % 2 ? { background: "#f4f6f8" } : { background: "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Visibility onClick={() => handleClick('ver', order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit', order)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;