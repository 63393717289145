import { gql } from 'apollo-boost';

//query

export const GET_ALLUSUARIOS = gql`
  query GetUsuarios{
    GetUsuarios{
      USR_Id
      USR_NombreCom
      USR_Descripcion
      USR_InCambio
      USR_Password
      USR_ultPwdChange
      USR_UGP_ID
      USR_Disabled
      USR_Direccion
      USR_Telefono
      USR_Email
      USR_CreatedDate
      USR_ModifiedDate
      USR_ModifiedBy
      USR_Active
      UGP_Nombre
    }
  }
`;

export const SUC_USER = gql`
  query GetSucursalUser($data: UserId){
    GetSucursalUser(data:$data){
      SSU_Id
      SSU_SUC_Id
      SSU_USR_Id
    }
  }
`

export const GET_LVL_USER = gql`
  query getLvlUser{
    getLvlUser{
      UGP_ID
      UGP_Nombre
    }
  }
`


//mutation

export const NEW_USUARIO = gql`
  mutation createUsuario($data: UsuarioInput){
    createUsuario(data:$data){
      USR_Id
      USR_NombreCom
      USR_Descripcion
      USR_InCambio
      USR_Password
      USR_ultPwdChange
      USR_UGP_ID
      USR_Disabled
      USR_Direccion
      USR_Telefono
      USR_Email
      USR_CreatedDate
      USR_ModifiedDate
      USR_ModifiedBy
      USR_Active
    }
  }
`;

export const EDIT_USUARIO = gql`
  mutation editUsuario($data: UsuarioInput){
    editUsuario(data:$data){
      USR_Id
      USR_NombreCom
      USR_Descripcion
      USR_InCambio
      USR_Password
      USR_ultPwdChange
      USR_UGP_ID
      USR_Disabled
      USR_Direccion
      USR_Telefono
      USR_Email
      USR_CreatedDate
      USR_ModifiedDate
      USR_ModifiedBy
      USR_Active
    }
  }
`;

