import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  GET_DATA_SUCURSAL
} from '../../../graphql/Selects';
import { NEW_USUARIO, GET_LVL_USER } from '../../../graphql/Usuarios';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LoadingSpiner from '../../../components/LoadingSpiner';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    minWidth: 490,
    maxWidth: 960,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  let arrayIds = []
  if (personName.length > 0) {
    personName.map(item => {
      arrayIds.push(item.value)
      return item
    })
  }
  return {
    fontWeight:
      arrayIds.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  //Obtener datos de local Storage
  let auth = JSON.parse(sessionStorage.getItem('sesTime'));
  //Mensaje al insertar
  const [open, setOpen] = useState(false);
  //Valor del select de sucursales
  const [sucursalesSelect, setSucursalesSelect] = useState([]);
  //Contenido del select de sucursales
  const [datasSucursal, setDatasSucursal] = useState([]);
  //Errores obtenidos al enviar
  const [errorForm, setErrorForm] = useState({});
  //Constante para manejar el estado del loading
  const [loading, setLoading] = useState(false);
  //Constante para alojar los niveles de usuario que vienen del backend
  const [levelSelect, setLevelSelect] = useState([]);
  const validationSchema = Yup.object().shape({
    USR_Id: Yup.string()
      .min(2, "Muy corto!")
      .required('Es requerido un nombre'),
    USR_NombreCom: Yup.string().required('Es requerido un nombre'),
    USR_Email: Yup.string().required('Es requerido un email'),
    USR_Telefono: Yup.string().required('Es requerido un Teléfono'),
    USR_Direccion: Yup.string().required('Es requerido una dirección'),
    USR_Descripcion: Yup.string().required('Es requerido un Descripción'),
    USR_Password: Yup.string()
      .matches(uppercaseRegex, 'Un caracter Mayuscula requerido!')
      .matches(lowercaseRegex, 'Un caracter Minuscula requerido!')
      .matches(numericRegex, 'Un numero requerido!')
      .min(8, 'Minimo 8 caracteres requeridos')
      .required('Es requerido una Contraseña'),
    USR_RePassword: Yup.string()
      .oneOf([Yup.ref('USR_Password')], 'Constraseña no coinciden')
      .required('Es requerido una Contraseña'),
    USR_Sucursales: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Es requerido una sucursal'),
        value: Yup.number().required('Es requerido una sucursal'),
      })
    )
  })
  //Obtener el arreglo de la sucursal seleccionada
  const handleChangeSelect = (event) => {
    //Guardo la variable en una mas facil de acceder
    let data = event.target.value
    //Recorro los datos entrantes
    data.map(response => {
      //Verifico el tipo de dato que contiene el array
      if (typeof response === "number") {
        if (sucursalesSelect.find((item,i) => item.value === response)){
          //Este apartado se cumple solo cuando se quiere eliminar una sucursal seleccionada
          let sinElNew = data.filter((value) => { return typeof value !== "number" })
          let borrado = sinElNew.filter((item) => { return item.value !== response })
          setSucursalesSelect(borrado)
        } else {
          //UNa vez verificado se busca el valor en el objeto para asignarlo a la variable
          let values = datasSucursal.find(item => item.value === response)
          setSucursalesSelect([...sucursalesSelect, values]);
        }
      }
      return response
    })
  };
  //query para llenar selector
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  //Query para obtener el lvl de usuario
  const { data: dataLvlUser, loading: loading1, error: error1 } = useQuery(GET_LVL_USER);
  //Mutation para enviar datos al backend
  const [sendUser] = useMutation(NEW_USUARIO, {
    onCompleted: data => {
      setLoading(false);
      if (data) {
        setOpen(true);
        setTimeout(() => {
          history.push({
            pathname: '/app/usuarios',
            state: { isRefetch: true },
          });
        }, 1000)
      }
    }
  })
  //Funcion para enviar datos al backend
  const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({})
    //Creo el objeto a validar
    let data = {
      USR_Id: values.USR_Id,
      USR_NombreCom: values.USR_NombreCom,
      USR_Email: values.USR_Email,
      USR_Telefono: values.USR_Telefono,
      USR_Direccion: values.USR_Direccion,
      USR_Descripcion: values.USR_Descripcion,
      USR_Password: values.USR_Password,
      USR_RePassword: values.USR_RePassword,
      USR_ModifiedBy: auth.USR_Id,
      USR_Sucursales: sucursalesSelect
    }
    //Valido con promesa
    validationSchema.validate(data)
    //Si es valido se envia el formulario
      .then(async (res) => {
        await sendUser({variables:{data}})
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch((err) => {
        setLoading(false);
        let object = {}
        object[err.path] = err.errors
        setErrorForm(object)
      })

  }
  //Funcion para detonar abrir o cerrar del modal de success
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setLoading(true)
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = []
      dataSucursal.GetDataSucursal.map(item => {
        return thing.push({ label: item.SUC_Nombre, value: item.SUC_Id })
      })
      setDatasSucursal(thing)
      setLoading(false)
    }
    if (dataLvlUser && loading1 === false && error1 === undefined) {
      let thing = []
      dataLvlUser.getLvlUser.map(item => {
        return thing.push({ label: item.UGP_Nombre, value: item.UGP_ID })
      })
      setLevelSelect(thing)
      setLoading(false)
    }
  }, [dataSucursal,
    loading4,
    error4,
    dataLvlUser,
    loading1,
    error1,
  ])
  return (
    <Formik
      initialValues={{
        USR_Id: '',
        USR_NombreCom: '',
        USR_Email: '',
        USR_Telefono: '',
        USR_Direccion: '',
        USR_Descripcion: '',
        USR_Password: '',
        USR_RePassword: '',
        USR_Sucursales: ''
      }}
      onSubmit={async (values) => sendData(values)}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Genera un nuevo usuario"
              title="Usuarios"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >

                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre Completo"
                    name="USR_NombreCom"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_NombreCom}                    
                  />
                  {errorForm.USR_NombreCom && touched.USR_NombreCom ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_NombreCom}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email"
                    name="USR_Email"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Email}                    
                  />
                  {errorForm.USR_Email && touched.USR_Email ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Email}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Teléfono"
                    name="USR_Telefono"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Telefono}                    
                  />
                  {errorForm.USR_Telefono && touched.USR_Telefono ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Telefono}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Dirección"
                    name="USR_Direccion"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Direccion}                 
                  />
                  {errorForm.USR_Direccion && touched.USR_Direccion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Dirección}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="USR_Descripcion"
                    label="Rol"
                    onBlur={handleBlur}
                    value={values.USR_Descripcion}
                    onChange={handleChange}                   
                  >
                    {!!levelSelect && (levelSelect.map((option) => (
                      <MenuItem key={`rol_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Login"
                    name="USR_Id"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.USR_Id}                    
                  />
                  {errorForm.USR_Id && touched.USR_Id ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Id}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label" required>Sucursales</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      required
                      value={sucursalesSelect}
                      onChange={handleChangeSelect}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((item) => (
                            <Chip key={item.value} label={item.label} className={classes.chip} />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {!!datasSucursal && (datasSucursal.map((item) => (
                        <MenuItem key={item.value} value={item.value} style={getStyles(item.value, sucursalesSelect, theme)}>
                          {item.label}
                        </MenuItem>
                      )))}
                    </Select>
                  </FormControl>
                  {errorForm.USR_Sucursales ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.USR_Password}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <FormControlLabel
                    control={(
                      <Checkbox defaultChecked />
                    )}
                    label="Activo"
                  />
                  <FormControlLabel
                    disabled
                    control={(
                      <Checkbox />
                    )}
                    label="Bloqueado"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

          </Card>
          <Box mt={3}>
            <Card>
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Contraseña"
                      name="USR_Password"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      value={values.USR_Password}                    
                    />
                    {errorForm.USR_Password && touched.USR_Password ? (
                      <Typography
                        color="error"
                        variant="subtitle2">
                        {errorForm.USR_Password}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Confirmar Contraseña"
                      name="USR_RePassword"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      value={values.USR_RePassword}                      
                    />
                    {errorForm.USR_RePassword && touched.USR_RePassword ? (
                      <Typography
                        color="error"
                        variant="subtitle2">
                        {errorForm.USR_RePassword}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}

                >
                  Guardar Usuario
                  </Button>
              </Box>
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Se ha creado con exito el usuario!
                </Alert>
              </Snackbar>
              <LoadingSpiner estado={loading} />
            </Card>
          </Box>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;