import { gql } from 'apollo-boost';

//query

export const GET_PARAMETROS = gql`
query GetParametros{
    GetParametros{
        PAR_Id
        PAR_Descripcion
        PAR_Type
        PAR_String
        PAR_Number        
        PAR_Type
    }
}
`;

export const EDIT_PARAMETROS = gql`
mutation editParametros($data:ParametrosInput){
    editParametros(data:$data){
        PAR_Id
        PAR_Descripcion
        PAR_Type
        PAR_String
        PAR_Number        
        PAR_Type
    }
}
`;