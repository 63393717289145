import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import {
  // AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  // Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  DollarSign as DollarSignIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  Monitor as MonitorIcon,
  Home as HomeIcon,
  Archive as ArchiveIcon,
  Settings as SettingsIcon,
  // Users as UsersIcon
} from 'react-feather';
import { NavItem, NavItemDropdown } from './NavItem';
import { arrayFrom } from '../../../Commons';
import InputIcon from '@material-ui/icons/Input';
import { useHistory } from 'react-router-dom';

const items = [
  {
    href: '/app/dashboard',
    icon: HomeIcon,
    title: 'Inicio'
  },
  {
    href: '/app/services',
    icon: ShoppingBagIcon,
    title: 'Servicios'
  },
  {
    href: '/app/gastos',
    icon: DollarSignIcon,
    title: 'Gastos'
  },
  {
    href: '/app/depositos',
    icon: ArchiveIcon,
    title: 'Depositos'
  },
  {
    href: '/app/inventario',
    icon: MonitorIcon,
    title: 'Inventario'
  },
  {
    href: '/app/usuarios',
    icon: LockIcon,
    title: 'Usuarios'
  },
  {
    href: '/app/Config',
    icon: BarChartIcon,
    title: 'Configuración'
  },
  {
    href: '/app/reports',
    icon: BarChartIcon,
    title: 'Reportes'
  },

];

const configItems = [
 
  // {
  //   href: '/app/MtdPago',
  //   icon: SettingsIcon,
  //   title: 'Metodo pago'
  // },
  {
    href: '/app/Sucursal',
    icon: SettingsIcon,
    title: 'Sucursal'
  },
  // {
  //   href: '/app/AddServicios',
  //   icon: BarChartIcon,
  //   title: 'Agregar Servicios'
  // },
  // {
  //   href: '/app/Parametros',
  //   icon: BarChartIcon,
  //   title: 'Parametros'
  // },
  // {
  //   href: '/app/Tax',
  //   icon: BarChartIcon,
  //   title: 'Tax'
  // },
  // {
  //   href: '/app/Barrio',
  //   icon: BarChartIcon,
  //   title: 'Barrios'
  // },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  buttonExit: {
    color: 'black'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [dataRoutes, setDataRoutes] = useState([]);
  const [user, setAvatar] = useState({
    jobTitle: '',
    name: ''
  })
  const userSession = JSON.parse(sessionStorage.getItem('sesTime'))
  const redireccionar = () => {
    sessionStorage.removeItem('sesTime');
    history.push('/')
  }
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (userSession && userSession.UGP_Nombre) {
      setAvatar({
        jobTitle: userSession.UGP_Nombre,
        name: userSession.USR_Id
      })
      setDataRoutes(arrayFrom(userSession.USR_UGP_ID));
    }
    // setAvatarFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, setAvatar]);
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/dashboard"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            dataRoutes.indexOf(item.title) !== -1 ?
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
              : null
          ))}
          <NavItemDropdown title={'Configuración'} items={configItems} />
          <Hidden lgUp>
            <RouterLink to='#' className={classes.buttonExit} onClick={redireccionar}>
              <IconButton color="inherit">
                <InputIcon />
              </IconButton>
            </RouterLink>
          </Hidden>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
