import { gql } from 'apollo-boost';

//query

export const GET_SUCURSALES = gql`
  query GetSucursales{
    GetSucursales{
      SUC_Id
      SUC_Nombre
      SUC_Nafta
      SUC_Activo
      SUC_Comi
      SUC_ByModify
      SUC_FechaM
    }
  }
`;

//mutation

export const NEW_SUCURSAL = gql`
  mutation createSucursal($data: SucursalInput){
    createSucursal(data:$data){
      SUC_Nombre
      SUC_Nafta
      SUC_ByModify
      SUC_Comi
    }
  }
`;

export const EDIT_SUCURSAL = gql`
  mutation editSucursal($data: SucursalInput){
    editSucursal(data: $data){
      SUC_Nombre
      SUC_Nafta      
      SUC_ByModify
      SUC_Activo
      SUC_Comi      
    }
  }
`;

export const DEL_SUCURSAL = gql`
mutation delSucursal($data: SucursalInput){
  delSucursal(data: $data){
    SUC_Id        
  }
}
`;