import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';
import Toolbar from './BackToolbar';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { GET_ALL_SELLaBILL } from "../../graphql/Report";
import { GET_DATA_SUCURSAL } from "../../graphql/Selects";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { 
  MapeoSucursales
} from '../../Commons'
import MenuItem from '@material-ui/core/MenuItem';

const columns = [
  { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'Venta_del_dia', headerName: 'Ventas del Dia', width: 130 },
  { field: 'Comision', headerName: 'Comision', width: 130 },
  { field: 'Gastos', headerName: 'Gastos', width: 130 },
  { field: 'Deposito', headerName: 'Deposito', width: 130 },
  { field: 'Diferencia', headerName: 'Diferencia', width: 130 },
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));


const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const [datasSucursal, setDatasSucursal] = useState();
  const { data: dataSucursal, loading, error } = useQuery(GET_DATA_SUCURSAL);
  const [findSales] = useLazyQuery(GET_ALL_SELLaBILL, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error al ejecutar la consulta:', error);
    },
    onCompleted: (data) => {
      setDataTable(data.GetAllSellaBill);
      console.log('DEBUG', data.GetAllSellaBill);
    },
  })

  useEffect(() => {
    if (dataSucursal && loading === false && error === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
  }, [dataTable, dataSucursal, loading, error])

  return (
    <Page
      className={classes.root}
      title="Informe de rendicion"
    >
      <Formik
        initialValues={{
          FechaIni: new Date().toISOString().substring(0, 10),
          FechaFin: new Date().toISOString().substring(0, 10),
          SERV_Sucursal: ''
        }}
        onSubmit={async (values) => {

          await findSales({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin,
                SERV_Sucursal: values.SERV_Sucursal,
              },
            }
          })

        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">
            <Toolbar className={classes.spaceButtom} />
            <CardContent>
              <Card>
                <CardHeader
                  title="Informe de rendiciones"
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"                          
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaIni && touched.FechaIni ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaIni}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"                          
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaFin && touched.FechaFin ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaFin}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          select
                          name="SERV_Sucursal"
                          label="Sucursal"
                          onBlur={handleBlur}
                          value={values.SERV_Sucursal}
                          onChange={handleChange}                         
                        >
                          {!!datasSucursal && (datasSucursal.map((option) => (
                            <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                              {option.label}
                            </MenuItem >
                          )))}
                        </TextField>
                        {errors.SERV_Sucursal && touched.SERV_Sucursal ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.SERV_Sucursal}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
              </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          {columns.map((rowsName) => (
                            <TableCell align="center" className={classes.head} key={rowsName.field}>
                              {rowsName.headerName}
                            </TableCell>

                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>

                      {!!dataTable.AllSellaBill && (dataTable.AllSellaBill.map((row, index) => (
                          <TableRow key={index}>                              
                              <TableCell align='center'>{row.SUC_Nombre}</TableCell>
                              <TableCell align='center'>{row.Venta_del_dia}</TableCell>
                              <TableCell align='center'>{row.Comision}</TableCell>
                              <TableCell align='center'>{row.Gastos}</TableCell>
                              <TableCell align='center'>{row.Deposito}</TableCell>
                              <TableCell align='center'>{row.Diferencia}</TableCell>
                            </TableRow>
                          ))
                        ) }

                       

                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-end"
            >
              <Grid
                item
                md={6}
                xs={12}
              >

                <CardContent>
                  <Card>
                    <Divider />
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="spanning table">
                        <TableBody>
                          <TableRow>
                            {/* <TableCell rowSpan={9} />
                            <TableCell> Acumula</TableCell>
                            <TableCell align='right'></TableCell> */}
                          </TableRow>

                        </TableBody>                         
                      </Table>
                    </TableContainer>
                  </Card>
                </CardContent>
              </Grid>

            </Grid>


          </Container>

        )}
      </Formik>
    </Page>
  );
}


export default DataTable;
