import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  Container,
  Snackbar,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { switchAuth } from '../../Commons';
import Logo from 'src/components/Logo';

import { useLazyQuery } from '@apollo/react-hooks';
import { LOGIN_USER } from "../../graphql/Login";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [errores, setErrores] = useState('');
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right'
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [validateLogin] = useLazyQuery(LOGIN_USER, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      console.log(data)
      switch (data.UserValidate.message) {
        case 'Success':
          let objectSession = {
            USR_Id: data.UserValidate.USR_Id,
            USR_NombreCom: data.UserValidate.USR_NombreCom,
            USR_UGP_ID: data.UserValidate.USR_UGP_ID,
            USR_Disabled: data.UserValidate.USR_Disabled,
            USR_Active: data.UserValidate.USR_Active,
            UGP_Nombre: data.UserValidate.UGP_Nombre
          }
          sessionStorage.setItem('sesTime', JSON.stringify(objectSession));
          // sessionStorage.setItem('USR_Id',data.UserValidate.USR_Id)
          sessionStorage.setItem('parametrosSys', JSON.stringify(data.UserValidate.parametros));
          sessionStorage.setItem('sucursalesUser', JSON.stringify(data.UserValidate.sucursales));
          let dataSwitch = switchAuth('dashboard', data.UserValidate.USR_UGP_ID)
          if (typeof dataSwitch != "boolean") {
            history.push(`/app/${dataSwitch}`);
          } else {
            history.push('/app/dashboard');
          }
          break;
        case 'newUser':
          sessionStorage.setItem('newUserId', data.UserValidate.USR_Id);
          history.push({
            pathname: '/newUser',
            // state: { data: { id: data.UserValidate.USR_Id} },
          });
          break;
        default:
          setState({ ...state, open: true });
          setErrores(data.UserValidate.message);
          break;
      }
    }
  });

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .max(255)
                .required('Es requerido un usuario'),
              password: Yup.string()
                .max(255)
                .required('Es requerida la clave')
            })}
            onSubmit={async values => {
              await validateLogin({
                variables: {
                  data: { id: values.email, password: values.password }
                }
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                  mb={6}
                >
                  <Typography color="textPrimary" variant="h2">
                    Ingresar
                  </Typography>
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Logo />
                    MAM
                  </Box>
                </Box>
                {/* {errores ? (
                  <Box mb={2}>
                    <Typography color="error" variant="h6">
                      {errores}
                    </Typography>
                  </Box>
                ) : null} */}
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Nombre de usuario"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Ingresar
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        severity="error"
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        key={vertical + horizontal}
      >
        <Alert severity="error">{errores}</Alert>
      </Snackbar>
    </Page>
  );
};

export default LoginView;
