import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { NewFormatDateShort } from '../../../Commons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import ModalItem from 'src/components/ModalImage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  buttonGoBack: {
    marginTop: '1%',
    marginRight: '1%',
  },
}));

const VerGastos = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const data = location.state;
  const setOpenModal = () => {
    setOpen(!open)
  }
  const goBackButton = () => {
    history.push('gastos')
  }
  return (
    <Page
      className={classes.root}
      title="Gastos"
    >
      <Container maxWidth="lg">
        <Card>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              className={classes.headerStyle}
              xs={12}
            >
              <CardHeader
                title={`Gasto ID: ${data.GAST_Id}`}
              />
              <Button
                className={classes.buttonGoBack}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={goBackButton}
              >
                Atras
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Creado por usuario:
                    </Typography>
              </Grid >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.GAST_User}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Descripción:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.GAST_Descripcion}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Método de pago:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.MTD_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Fecha:
                  </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {NewFormatDateShort(data.GAST_Fecha)}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Sucursal:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.SUC_Nombre}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Importe:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {`$${(data.GAST_Importe).toFixed(2)} `}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Numero de factura:
                    </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  {data.GAST_NumFact}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Typography variant="body2" component="p">
                  Recibo:
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <img alt={data.GAST_NumFact} src={data.GAST_Recibo} width={100} onClick={(e) => {setOpenModal()}} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <ModalItem estado={open} openModal={setOpenModal} content={data.GAST_Recibo} />
    </Page>
  )
}

export default VerGastos;
