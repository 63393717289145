import { gql } from 'apollo-boost';

//query

export const GET_ALLTSERVICIOS = gql`
  query GetAddServicio{
    GetAddServicio{
      TIPO_Id
      TIPO_Nombre
      TIPO_Fecha
      TIPO_Activo
      TIPO_ByModify
    }
  }
`;

//mutation

export const NEW_ADDSERVICIOS = gql`
  mutation createAddServicio($data: AddServicioInput){
    createAddServicio(data:$data){
     TIPO_Nombre
     TIPO_ByModify
    }
  }
`;

export const EDIT_ADDSERVICIOS = gql`
  mutation editAddServicio($data: AddServicioInput){
    editAddServicio(data:$data){
     TIPO_Nombre
     TIPO_ByModify
    }
  }
`;
