import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NewFormatDateLong } from '../../Commons';
import MyCalendar from './CalendarTable';
import Modal from '../../shared/componentes/Modal';
import { DEL_SERVICES } from '../../graphql/Services';
import { useMutation } from '@apollo/react-hooks';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DataTable = (props, { className, ...rest }) => {
  const [dataTable, setDataTable] = useState([])
  const [open, setOpen] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [dataEvent, setDataEvent] = React.useState();

  const handleClickOpen = data => {
    setOpen(!open);
    setDataEvent(data)
  };
  const handleClickOpenSnackbar = () => {
    setOpenSnackBar(!openSnackBar);
  };
  const history = useHistory();
  const [delService] = useMutation(DEL_SERVICES, {
    onCompleted: ({ delService }) => {
      if (delService) {
        props.refetch()
        handleClickOpen()
        handleClickOpenSnackbar()
      }
    }
  })
  const location = useLocation();

  const handleEdit = () => {
    history.push({
      pathname: 'EditService',
      state: dataEvent,
      isRefetch: { isRefetch: true }
    })
  }
  const handleVer = () => {
    history.push({
      pathname: 'verServicio',
      state: dataEvent,
      isRefetch: { isRefetch: true }
    })
  }
  const handleDel = () => {
    delService({ variables: { data: { SERV_Id: dataEvent.SERV_Id, users: JSON.parse(sessionStorage.getItem('sesTime')).USR_Id } } })
  }
  useEffect(() => {
    let thing = []
    if (props.servicesData && props.loading_Services === false && props.error_Services === undefined) {
      props.servicesData.GetServices.map(item => {
        item['id'] = item.SERV_Id;
        item['start'] = new Date(NewFormatDateLong(item.SERV_Fecha))
        item['end'] = new Date(item.SERV_FechaF != null ? NewFormatDateLong(item.SERV_FechaF) : NewFormatDateLong(item.SERV_Fecha))
        item['title'] = item.ZON_Barrio
        return thing.push(item)
      })
      setDataTable(thing);
    }
  }, [props.servicesData, props.error_Services, props.loading_Services, location])
  return (
    <>
      <MyCalendar events={dataTable} handleClick={handleClickOpen} />
      <Modal 
        handleClickOpen={handleClickOpen}
        open={open}
        content={'Estas son las opciones'}
        handleClose={handleClickOpen}
        handleEdit={handleEdit}
        handleVer={handleVer}
        handleDel={handleDel}
      />
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClickOpenSnackbar}>
        <Alert onClose={handleClickOpenSnackbar} severity="success">
          Se ha eliminado con exito el servicio!
        </Alert>
      </Snackbar>
    </>
  );
}

export default DataTable;