import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Card,
  Box,
  CardContent,
  Divider,
  Typography,
  Grid,
  List,
} from '@material-ui/core';
import { GET_DEPOSITO, GET_SERVICIO } from "../../../graphql/Dashboard";
import { getSumTotalDash, getValorReal } from '../../../Commons';
import { useQuery } from '@apollo/react-hooks';
import { ItemListComponent } from '../../../components/ItemListComponent';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    overflow: 'auto',
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '25px',
    height: '50px',
    margin: '8px',
  },
  footerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: '25px',
    height: '50px',
    margin: '8px',
  },
  titleBox: {
    marginLeft: '20px',
    fontSize: '15px',
  },
  dividerMargin: {
    marginTop: '15px',
    marginLeft: '30px',
  }
}));

export default function MediaCard() {
  const classes = useStyles();
  const [cajasData, setCajasData] = useState([])
  const [depositosData, setDepositosData] = useState([])
  const { loading: loadingDepositos, error: errorDepositos, data: depositoData } = useQuery(GET_DEPOSITO,{
    fetchPolicy: "no-cache",
  })
  const { loading: loadingCaja, error: errorCaja, data: cajaData } = useQuery(GET_SERVICIO, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (depositoData && loadingDepositos === false && errorDepositos === undefined) {
      let thing = []
      depositoData.GetDepositosDashboard.map(item => {
        return thing.push(item)
      })
      setDepositosData(thing);
    }
    // if (cajaData && loadingCaja === false && errorCaja === undefined) {
    //   // let thing = []
    //   // cajaData.GetServicioDashboard.map(item => {
    //   //   return thing.push(item)
    //   // })
    //   let comi = getFromStore(JSON.parse(localStorage.getItem('parametrosSys')), 'COMI');
    //   let thing = getValorReal(cajaData.GetServicioDashboard, comi);
    //   setCajasData(thing);
    // }
  }, [
    loadingDepositos,
    errorDepositos,
    depositoData,
    loadingCaja,
    errorCaja,
    cajaData
  ])

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid className={classes.headerStyle} item xs={12}>
          <Typography>
            Disponible
          </Typography>
          <Typography>
            ${(Number(getSumTotalDash(cajasData, 'Caja')) + Number(getSumTotalDash(depositosData, 'Depostios'))).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.titleBox} variant="h6" gutterBottom>
            Cajas
          </Typography>
          <CardContent className={classes.media}>
            <div>
              <List dense={true}>
                {!!cajasData && cajasData.map((item) => <ItemListComponent title={item.CTA_Nombre} value={item.SERV_SID_ImporteNeto} />)}
              </List>
            </div>
          </CardContent>
          <Divider className={classes.dividerMargin} />
          <Box className={classes.footerStyle}>
            <Typography>
              Total caja
            </Typography>
            <Typography>
              ${getSumTotalDash(cajasData,'Caja')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.titleBox} variant="h6" gutterBottom>
            Bancos
          </Typography>
          <CardContent className={classes.media}>
            <div>
              <List dense={true}>
                {!!depositosData && depositosData.map((item) => <ItemListComponent title={item.CTA_Nombre} value={item.SERV_SID_Importe} />)}
              </List>
            </div>
          </CardContent>
          <Divider className={classes.dividerMargin} />
          <Box className={classes.footerStyle}>
            <Typography>
              Total bancos
            </Typography>
            <Typography>
              ${getSumTotalDash(depositosData,'Depostios')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
