import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

export const Footer = () => {
  return (
    <Grid
      style={{
        backgroundColor: '#ffffff',
        borderTop: '1px solid #dbdcdf',
        height: '25px',
        borderRadius: '2px'
      }}
      container
    >
      <Grid
        container
        item
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Box>
          <Typography style={{ fontWeight: 'bold', fontSize: '15px' }} variant="p">
            Desarrollado por{' '}
            <a href="https://unelca.com.ar" target="_blank">
              Unelca
            </a>{' '}
            ©2021
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
