import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  GET_DATA_SUCURSAL
} from '../../../graphql/Selects';
import { NEW_INVENTARIO } from '../../../graphql/Inventario';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { MapeoSucursales, GetEquipoInv } from '../../../Commons';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const itemSession = JSON.parse(sessionStorage.getItem('sesTime'));

  const [datasSucursal, setDatasSucursal] = useState([])
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  const [sendInventario] = useMutation(NEW_INVENTARIO, {
    onCompleted: data => {
      if (data) {
        setOpen(true)
        setTimeout(() => {
          history.push({
            pathname: '/app/inventario',
            state: { isRefetch: true },
          });
        }, 1000)
      }
    }
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
  }, [dataSucursal,
    loading4,
    error4,
  ])
  return (
    <Formik
      initialValues={{
        AF_FechaCompra: '',
        AF_Sucursal: '',
        AF_Nombre: '',
        AF_Modelo: '',
        AF_Serial: '',
        AF_Asignada: '',
        AF_Proveedor: '',
        AF_Factura: '',
        AF_Costo: 0,
      }}
      validationSchema={Yup.object().shape({
        AF_FechaCompra: Yup.date().required('Es requerido una fecha valida'),
        AF_Sucursal: Yup.string().required('Es requerido una sucursal'),
        AF_Nombre: Yup.string().max(60).required('Es requerido un nombre'),
        AF_Modelo: Yup.string().max(60).required('Es requerido un model'),
        AF_Serial: Yup.string().max(60).required('Es requerido un serial'),
        AF_Asignada: Yup.string().max(60).required('Es requerido decir a quien esta asignada'),
        AF_Proveedor: Yup.string().max(60).required('Es requerido un proveedor'),
        AF_Factura: Yup.string().max(60).required('Es requerido un N° de factura'),
        AF_Costo: Yup.number().required('Es requerido un importe').positive('Importe debe ser mayor de 0').integer(),
      })}
      onSubmit={async (values) => {
        console.log(values)
        await sendInventario({
          variables: {
            data: {
              AF_FechaCompra: values.AF_FechaCompra,
              AF_Sucursal: values.AF_Sucursal,
              AF_Nombre: values.AF_Nombre,
              AF_Modelo: values.AF_Modelo,
              AF_Serial: values.AF_Serial,
              AF_Asignada: values.AF_Asignada,
              AF_Proveedor: values.AF_Proveedor,
              AF_Factura: values.AF_Factura,
              AF_Costo: values.AF_Costo,
              AF_ByModify: itemSession.USR_Id
            },
          }
        })

      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Agregar un nuevo equipo al sistema"
              title="Inventario"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    // label="Fecha de Compra"
                    label="Fecha"
                    name="AF_FechaCompra"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    value={values.AF_FechaCompra}                    
                  />
                  {errors.AF_FechaCompra && touched.AF_FechaCompra ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_FechaCompra}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    label="Sucursal"
                    name="AF_Sucursal"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.AF_Sucursal}                    
                  >
                    {!!datasSucursal && (datasSucursal.map((option) => (
                      <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errors.AF_Sucursal && touched.AF_Sucursal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Sucursal}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    label="Nombre Equipo"
                    name="AF_Nombre"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.AF_Nombre}
                  >
                    {!!GetEquipoInv && (GetEquipoInv.map((option) => (
                      <MenuItem key={`nombre_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errors.AF_Nombre && touched.AF_Nombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Nombre}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Marca / Modelo"
                    name="AF_Modelo"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.AF_Modelo}                    
                  />
                  {errors.AF_Modelo && touched.AF_Modelo ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Modelo}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Serial"
                    name="AF_Serial"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.AF_Serial}                    
                  />
                  {errors.AF_Serial && touched.AF_Serial ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Serial}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Asignada"
                    name="AF_Asignada"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.AF_Asignada}                    
                  />
                  {errors.AF_Asignada && touched.AF_Asignada ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Asignada}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Proveedor"
                    name="AF_Proveedor"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.AF_Proveedor}                    
                  />
                  {errors.AF_Proveedor && touched.AF_Proveedor ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Proveedor}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Factura"
                    name="AF_Factura"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.AF_Factura}                    
                  />
                  {errors.AF_Factura && touched.AF_Factura ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Factura}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Costo"
                    name="AF_Costo"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.AF_Costo}                    
                  />
                  {errors.AF_Costo && touched.AF_Costo ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.AF_Costo}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Guardar Equipo
          </Button>
            </Box>
          </Card>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Se ha creado con exito el equipo de inventario!
              </Alert>
          </Snackbar>
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;